import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getURLModule, getURLBaseSection } from "../../../utils/url";
import { downloadReport, getReportInfoWithoutFilters } from "../../../api/reportingAPI";
import { getForm, getFormFields } from "../../../api/formsAPI";
import { CloseRounded, GetAppRounded, SearchRounded } from "@material-ui/icons";
import { InputBase, MenuItem, Paper, Select } from "@material-ui/core";
import { detailsReportMapping } from "../../../utils/reporting";
import { updateReportingOptionsPreferences } from "../../../store/actions/swiftComplyActions";
import { getDefaultReportingParams } from "../../../utils/reporting";
import { getErrorText } from "../../../utils/errors";
import { reportInputToFilterField } from "../../../utils/forms";
import IconButton from "../IconButton";
import BasicReportTable from "./BasicReportTable";
import ColumnMenu from "./ColumnMenu";
import FilterMenu from "./FilterMenu";
import LoadingSpinner from "../LoadingSpinner";
import styles from "../../../styles/common/reportTable.module.css";
import searchStyles from "../../../styles/common/searchBox.module.css";
import searchInputStyles from "../../../styles/common/searchInput.module.css";
import searchBySelectStyles from "../../../styles/common/searchBySelect.module.css";

/**
 * Renders an "advanced" report table with search, filters, and column selection
 *
 * @param {string} reportUUID - The report to be fetched
 * @param {Object} [reportParams] - Any additional parameters, such as search
 * @param {string[]} [defaultColumns] - Columns to be displayed if the current user has not yet set them in preferences
 * @param {(Object) => string} [rowLinks] - Called when rendering each row, can return a link for when the row is clicked
 * @param {Object} [columnFormats] - Object containing functions called when rendering each cell, can be used to alter contents
 * @param {Object} [columnAlignments] - Object containing an optional CSS "text-align" value for each column
 */
const AdvancedReportTable = ({
    reportUUID,
    reportParams,
    defaultColumns,
    rowLinks,
    columnFormats,
    columnAlignments,
}) => {
    const history = useHistory();
    const { user } = useSelector((state) => state.swiftComply);
    const dispatch = useDispatch();
    const [reportTitle, setReportTitle] = useState("");
    const [allColumns, setAllColumns] = useState([]);
    const [filterFields, setFilterFields] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [searchBy, setSearchBy] = useState("");
    const [defaultSearchBy, setDefaultSearchBy] = useState("");
    const [searchableColumns, setSearchableColumns] = useState([]);
    const [error, setError] = useState(null);
    const [reportingParams, setReportingParams] = useState({
        ...{ ...getDefaultReportingParams(), count: 50 },
        ...(reportParams || {}),
    });
    const baseSection = getURLBaseSection();
    const options = JSON.parse(user?.preferences?.["reporting options"] || "{}");
    let { columns } = options[baseSection]?.[reportTitle] ?? {};

    if (!columns?.length) {
        columns = defaultColumns;
    }

    useEffect(() => {
        let reportInfo = null;
        let isMounted = true;

        getReportInfoWithoutFilters(reportUUID)
            .then((data) => {
                if (!isMounted) return;

                reportInfo = data;

                setReportTitle(data.name);

                const processedFilterFields = reportInfo.inputs.map(reportInputToFilterField);

                // Setting initial filter fields
                setFilterFields(processedFilterFields);

                // Setting initial selected filters based on default values
                const initialSelectedFilters = processedFilterFields.reduce((acc, field) => {
                    acc[field.id] = field.defaultValue;
                    return acc;
                }, {});

                setSelectedFilters(initialSelectedFilters);

                setSearchableColumns(reportInfo.outputs?.filter((col) => col.searchable)?.map((col) => col.name) ?? []);
                setDefaultSearchBy(data.default_search);

                return Promise.all(
                    reportInfo.details.map((detailsType) =>
                        getForm(detailsReportMapping[detailsType]).then((data) =>
                            data ? getFormFields(data.form_uuid) : { form_fields: [] }
                        )
                    )
                );
            })
            .then((detailsArray) => {
                if (!isMounted) return;

                const allDetails = detailsArray.map((details) =>
                    details.form_fields.filter(
                        // This filter removes fields that have a label like "Name" that conflicts with basic
                        // property info fields - bug filed to fix this
                        (field) => !reportInfo.outputs.some((output) => output.name === field.label)
                    )
                );

                setReportingParams((prevParams) => ({
                    ...prevParams,
                    page: 1, // Reset to first page if report ID changes
                    include_columns: reportInfo.details.reduce(
                        (prev, curr, i) => ({
                            ...prev,
                            [curr]: {
                                details: allDetails[i].map((field) => field.form_field_uuid),
                            },
                        }),
                        {}
                    ),
                }));
            })
            .catch((error) => {
                if (!isMounted) return;

                setError(error);
            });

        return () => {
            isMounted = false;
        };
    }, [reportUUID]);

    useEffect(() => {
        // Default "search by" has changed, reset the current "search by" setting
        setSearchBy(defaultSearchBy);
    }, [defaultSearchBy]);

    const handleFilterChange = (newFilters) => {
        setSelectedFilters(newFilters);

        const filtersForApi = Object.entries(newFilters).reduce((acc, [key, value]) => {
            if (typeof value === "boolean") {
                acc[key] = value.toString();
            } else if (value) {
                acc[key] = value;
            }

            return acc;
        }, {});

        setReportingParams((prevParams) => ({
            ...prevParams,
            inputs: filtersForApi,
            page: 1, // Reset to first page if filters change
        }));
    };

    const handleSearchChange = () => {
        const searchObj = {
            // API does not allow "" as a search, so undefine it if empty
            search: searchFieldValue ? { [searchBy.toLowerCase()]: searchFieldValue } : undefined,
        };

        setReportingParams((prevParams) => ({
            ...prevParams,
            ...searchObj,
            page: 1, // Reset to first page if search changes
        }));
    };

    const clearSearch = () => {
        setSearchFieldValue("");
        setReportingParams((prevParams) => ({
            ...prevParams,
            search: undefined,
            page: 1,
        }));
    };

    if (error) {
        return getErrorText(error);
    }

    return (
        <div className={styles.advancedTable}>
            <div className={styles.advancedHeader}>
                <Paper variant="outlined" classes={searchStyles}>
                    <IconButton icon={<SearchRounded />} onClick={handleSearchChange} />
                    <InputBase
                        classes={searchInputStyles}
                        placeholder="Search..."
                        value={searchFieldValue}
                        onChange={(e) => setSearchFieldValue(e.target.value ?? "")}
                        onKeyDown={(e) => e.key === "Enter" && handleSearchChange()}
                    />
                    {searchFieldValue && <IconButton icon={<CloseRounded />} onClick={clearSearch} />}
                    <Select
                        classes={searchBySelectStyles}
                        value={searchBy}
                        onChange={(event) => setSearchBy(event.target.value)}
                        disableUnderline
                    >
                        {searchableColumns.map((col) => (
                            <MenuItem key={col} value={col}>
                                {col}
                            </MenuItem>
                        ))}
                    </Select>
                </Paper>
                <IconButton
                    icon={<GetAppRounded />}
                    onClick={() => downloadReport(reportUUID, reportingParams, reportTitle)}
                    tooltip={{ title: "Download CSV" }}
                />
                <FilterMenu
                    filterFields={filterFields}
                    selectedFilters={selectedFilters}
                    onApplyFilters={handleFilterChange}
                />
                {!!allColumns?.length && (
                    <ColumnMenu
                        columns={allColumns.filter((c) => !c.match(/uuid/i))}
                        selectedColumns={columns}
                        onApply={(columns) =>
                            dispatch(updateReportingOptionsPreferences(baseSection, reportTitle, columns))
                        }
                    />
                )}
            </div>
            {reportingParams ? (
                <BasicReportTable
                    reportUUID={reportUUID}
                    onColumnsChanged={setAllColumns}
                    visibleColumns={columns?.length ? columns : allColumns}
                    rowLinks={rowLinks}
                    columnFormats={columnFormats}
                    columnAlignments={columnAlignments}
                    reportingParams={reportingParams}
                    setReportingParams={setReportingParams}
                    history={history}
                />
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};

export default AdvancedReportTable;
