import React from "react";
import { withRouter } from "react-router";
import { AddRounded } from "@material-ui/icons";
import { getPathname } from "../../../utils/url";
import styles from "../../../styles/common/home.module.css";
import Page from "../Page";
import Card from "../Card";
import Button from "../Button";

/**
 * A "Home" is a general page that shows a particular data type, report table, etc
 * - Example: <ContactsHome> shows a report table of all of the "Contacts" in the system
 *
 * @param {string} title - Title to be displayed at the top of the page, also used for saving preferences
 * @param {string} [cardTitle] - Optional title for the Card component
 * @param {string} [addNewFormName] - Creates the link to the "add new" form, for example, if addNewFormName is
 *     "addContact" then the link generated will look like "/bf/contacts/addContact"
 * @param {string} [addNewButtonText] - Text to be displayed on the "add new" button
 * @param {Object|string} [error] - see <Page>
 * @param {boolean} [loading] - see <Page>
 * @param {React.ReactNode} children
 * @param {*} [history]
 */
const Home = ({ title, cardTitle, addNewFormName, addNewButtonText, loading, error, children, history }) => {
    const pathname = getPathname();

    return (
        <Page loading={loading} error={error}>
            <div className={styles.header}>
                <h1 className={styles.title}>{title}</h1>
                {addNewButtonText && addNewFormName && (
                    <Button color="primary" size="large" onClick={() => history.push(`${pathname}/${addNewFormName}`)}>
                        <AddRounded className={styles.addIcon} /> {addNewButtonText}
                    </Button>
                )}
            </div>
            <Card title={cardTitle}>{children}</Card>
        </Page>
    );
};

export default withRouter(Home);
