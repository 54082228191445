import React from "react";
import { withRouter } from "react-router";
import { getPathname } from "../../../utils/url";
import Card from "../Card";
import BasicReportTable from "../ReportTable/BasicReportTable";

const PropertyContacts = ({ propertyUUID, history }) => {
    const pathname = getPathname();

    return (
        <Card title="Contacts" onCreate={() => history.push(`${pathname}/addContact`)} onCreateLabel="New Contact">
            <BasicReportTable
                reportUUID="a3666110-7257-4ad2-b741-ddd73ed34e99"
                reportingParams={{ inputs: { "Property UUID": propertyUUID } }}
                rowLinks={(row) => `${pathname}/contacts/${row["Contact UUID"]}`}
            />
        </Card>
    );
};

export default withRouter(PropertyContacts);
