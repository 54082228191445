import React, { useState, useEffect } from 'react';
import LocationDetailsForm from './LocationDetailsForm';
import editModeIcon from '../../../images/editMode.svg';
import {
	editProperty, createProperty, deleteProperty, getProperty,
	getPropertyDetails, savePropertyDetail, getPropertyContactListReport, updatePropertyContact,
	acceptProperty, rejectProperty, getPropertyCompliance, updatePropertyCompliance, updatePropertyOverallCompliance,
	getPropertySRFields, updatePropertySRFields
} from '../../api/propertiesAPI';
import { getEquipmentProperty, createEquipmentProperty } from '../../api/equipmentAPI';
import { getForm, getFormFields } from '../../api/formsAPI';
import { checkDuplicates } from '../../api/duplicatesAPI';
import { ModalDuplicateContext } from '../../contexts/ModalDuplicateContext';
import { updateURL } from '../../utils/url';
import validator from 'validator';
import Flyout from '../Flyout';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import { FormGroup, FormControlLabel, Switch, Container, Box, Typography } from '@material-ui/core'
import { reportRowToObject } from '../../utils/reporting';
import { APIUserError } from '../../api/api';
import DialogContainer from '../OldCommon/Containers/Dialog'
import Loading from '../Common/LoadingSpinner'
import { getEquipmentReport, updateEquipment } from '../../api/equipmentAPI'
import { getDefaultReportingParams } from '../../utils/reporting'
import StyledSwitch from "../OldCommon/StyledSwitch"


const LocationDetails = (props) => {
	const [editMode, setEditMode] = useState(false);
	const [deleteMode, setDeleteMode] = useState(false);
	const [data, setData] = useState({});
	const [apiErrors, setApiErrors] = useState({});
	const [isNew, setIsNew] = useState(props.newAddition);
	const [flyoutActive, setFlyoutActive] = useState(false);
	const [propertyContacts, setPropertyContacts] = useState([]);
	const [lastSurvey, setLastSurvey] = useState(null);
	const [ locationAssemblies, setLocationAssemblies ] = useState(null)
	const [ assemblyConfirmationPage, setAssemblyConfirmationPage ] = useState(null)

	useEffect(() => {
		if (props.newAddition) {
			setEditMode(true);
			getAdditionalDetailsInfo();
		} else {
			getLocationData(props.uuid);
		}
	}, []);

	const getLocationData = async (uuid) => {
		if (uuid !== undefined && uuid !== '') {
			// we can get here either via the locations flyout or assemblies, locations will have sent us the property
			// uuid as uuid, assemblies we need to look it up.
			let propertyUUID = undefined;
			if (props.flyoutType === "assemblies") {
				let equipmentProperty = await getEquipmentProperty(uuid);
				if (equipmentProperty.property_uuid && !equipmentProperty.removed_on) {
					getPropertyInfo(equipmentProperty.property_uuid);
					propertyUUID = equipmentProperty.property_uuid;
				} else {
					getAdditionalDetailsInfo();
					setIsNew(true);
				}
			} else {
				getPropertyInfo(uuid);
				propertyUUID = uuid;
			}

			// we can't pull survey info if we have no property uuid, so make sure we found one first
			if (propertyUUID !== undefined) {
				let survey = null;
				try {
					survey = await getPropertyCompliance(propertyUUID, "Survey");
				} catch (e) {
					// ignore, this will 404 if there was no inspection entered yet
				}
				setLastSurvey(survey);
			}
		}
	};

	const getPropertyInfo = (uuid) => {
		getProperty(uuid).then(response => {
			getPropertySRFields(uuid).then(srresp => {
				// response here gets passed through to the addl details lookup and then used as the property data so
				// just plug our sr_fields in here since that is how it used to return
				response.sr_fields = srresp.sr_fields;
				let reportingParams = { inputs: { "Property UUID": uuid } };
				getPropertyContactListReport(reportingParams).then(report => {
					let contacts = [];
					report.data.rows.forEach(row => {
						// I think reportRowToObject should be changed to convert types, that was the original intention
						// but it was forgotten and now would involve going through the entire site and making sure it
						// doens't break something.  So I'll do that later but for now convert primary so its done
						let contact = reportRowToObject(row.values, report.data.outputs);
						contact.primary_contact = (contact.primary_contact.toLowerCase() === "true");

						contacts.push(contact);
					});
					setPropertyContacts(contacts);

					getAdditionalDetailsInfo(response);
					if (props.flyoutType === "locations") {
						props.setHeader(response.name, response.active);
					}
				});
			});
		});
	}

	const getAdditionalDetailsInfo = (locationData) => {
		if (locationData === undefined) {
			locationData = {};
		}
		locationData.c3_additional_details = {};

		getForm("property details").then(form => {
			if (form != null) {
				getFormFields(form.form_uuid).then(response => {
					response.form_fields.forEach((field) => {
						locationData.c3_additional_details[field.form_field_uuid] = field;
					});

					if (locationData.property_uuid !== undefined) {
						getPropertyDetails(locationData.property_uuid).then(response => {
							response.details.forEach((detail) => {
								// check to make sure we know about this field (detail) as we could have had old details
								// from old custom fields returned
								if (locationData.c3_additional_details[detail.form_field_uuid]) {
									locationData.c3_additional_details[detail.form_field_uuid].value = detail.value;
								}
							});
							setData(locationData);
						});
					} else {
						setData(locationData);
					}
				});
			} else {
				setData(locationData);
			}
		});
	}

	const saveDetails = async (ctx, uuid, payload) => {
		if (isNew) {
			checkDuplicates('properties', payload.name).then(response => {
				if (response.duplicates.length > 0) {
					ctx.setDuplicates(
						response.duplicates,
						[
							{ name: 'address', data: 'address1' },
							'city'
						],
						'/locations',
						payload,
						saveNewLocation,
						useSelectedDuplicate
					);
				} else {
					saveNewLocation(payload);
				}
			});
		} else {
			let apiErrors = {};
			let editModeActive = false;
			let sr_fields = payload.sr_fields;
			delete payload.sr_fields;
			try {
				// pull out the primary contact to set later, and remove it from the payload we will send to editProperty
				const primaryContactUUID = payload.primary_contact_uuid;
				delete payload.primary_contact_uuid;

				editProperty(uuid, payload)
					.then(async response => {
						// If property is successfully edited, check to see if needs_review is true and automatically accept this pending property since if it's
						// being edited it must either be a valid property or has been edited to be so.
						if (data.needs_review === true) {
							acceptProperty(data.property_uuid).then(() => {
								getLocationData(props.uuid);
							})
						}

						updatePropertySRFields(uuid, sr_fields);
						await saveAdditionalDetails(uuid, payload);
						props.setHeader(response.name, response.active);

						let updateCompliancePayload = {};
						// if the next_survey or compliance_date comes back as something like 2020-04-20 then they didn't actually
						// change the field, but if it came back as an epoch, they did, so if so save it
						if (payload.next_survey && Number.isInteger(payload.next_survey)) {
							updateCompliancePayload.expiration_date = payload.next_survey;
						}
						if (payload.compliance_date && Number.isInteger(payload.compliance_date)) {
							updateCompliancePayload.compliance_date = payload.compliance_date;
						}
						// update compliant if we set it (it comes to here as a string) and the last survey is null or
						// if it doesn't match what the last test was
						if (payload.compliant !== '' && lastSurvey === null ||
							payload.compliant === 'true' && lastSurvey.compliant === false ||
							payload.compliant === 'false' && lastSurvey.compliant === true
						) {
							updateCompliancePayload.compliant = (payload.compliant === 'true') ? true : false;
						}
						if (Object.keys(updateCompliancePayload).length > 0) {
							// if we updated any of the compliance data, send that to the backend now
							await updatePropertyOverallCompliance(data['property_uuid'], updateCompliancePayload);
							await updatePropertyCompliance(data['property_uuid'], "Survey", updateCompliancePayload);
						}

					})

				if (!primaryContactUUID) {
					// if we don't have a primary contact, they might be trying to update it to no one, so in that case
					// we need to find if we have a contact that is marked primary in our list, and update them to not be
					let primaryContact = propertyContacts.find((contact) => contact.primary_contact);
					if (primaryContact !== undefined) {
						updatePropertyContact(
							uuid,
							primaryContact.contact_uuid,
							{ primary_contact: false, contact_types: primaryContact.types.split(",") }
						);
					}
				} else {
					// find our primary contact object in our list, and if it isn't set as the primary, then the user
					// is trying to update it so tell the backend
					let primaryContact = propertyContacts.find((contact) => contact.contact_uuid == primaryContactUUID);
					if (!primaryContact.primary_contact) {
						updatePropertyContact(
							uuid,
							primaryContact.contact_uuid,
							{ primary_contact: true, contact_types: primaryContact.types.split(",") }
						);
					}
				}
			}
			catch (error) {
				if (error instanceof APIUserError) {
					apiErrors = error.apiFieldErrors;
					if (error.apiFieldErrors['expiration_date']) {
						delete error.apiFieldErrors['expiration_date'];
						apiErrors['survey_test'] = 'Next survey due date can not be set to before the last survey date';
					}
				}
			};
			setApiErrors(apiErrors);
			setEditMode(editModeActive);
			props.refresh();
		}
	};

	const saveNewLocation = (payload) => {
		createProperty(payload)
			.then(async response => {
				await updatePropertySRFields(response.property_uuid, payload.sr_fields);
				await saveAdditionalDetails(response.property_uuid, payload);
				getLocationData(response.property_uuid);
				setEditMode(false);
				setIsNew(false);

				if (props.flyoutType === "assemblies") {
					linkPropertyToEquipment(response.property_uuid, props.uuid);
				} else {
					props.refresh();
					props.setHeader(response.name, response.active);
					props.openFlyout(response, false);
				}
			})
			.catch(error => {
				if (error.name === 'APIUserError') {
					setApiErrors(error.apiFieldErrors);
				}
			});
	}

	const useSelectedDuplicate = async (uuid) => {
		getPropertyInfo(uuid);
		setEditMode(false);
		setIsNew(false);
		if (props.flyoutType === "locations") {
			updateURL('/locations/' + uuid, {});
		}
		else if (props.flyoutType === "assemblies") {
			linkPropertyToEquipment(uuid, props.uuid);
		}
	}

	const linkPropertyToEquipment = (propertyUUID, equipmentUUID) => {
		createEquipmentProperty(equipmentUUID, propertyUUID).then(() => {
			if (props.refresh) {
				props.refresh();
			}
		});
	}

	const saveAdditionalDetails = async (uuid, payload) => {
		for (let fieldName in payload) {
			// so janky but whatever
			if (validator.isUUID(fieldName)) {
				if (data.c3_additional_details[fieldName].value !== payload[fieldName]) {
					if (!isNew || payload[fieldName] !== "") {
						savePropertyDetail(uuid, {
							"form_field_uuid": fieldName,
							"value": "" + payload[fieldName] // make sure what we send to the API is a string
						});
					}
				}
			}
		}
	};

	const openFlyout = () => {
		const overlay = document.getElementById('overlaySecondary');
		setFlyoutActive(true);
		overlay.classList.add('overlaySecondary__visible');
	};

	const closeFlyout = () => {
		const flyout = document.getElementById('flyoutSecondary');
		const overlay = document.getElementById('overlaySecondary');

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlaySecondary__visible');

		getLocationData(props.uuid);

		setTimeout(() => {
			setFlyoutActive(false);
		}, 200);

	};

	const toggleEditMode = (type) => {
		if (type === 'assembly' && data.property_uuid) {
			window.open(`/locations/${data.property_uuid}`, '_blank');
		} else {
			setEditMode(!editMode);
			setDeleteMode(false);
		}
	};

	const deleteThisLocation = async (data, type) => {

		if (type === 'delete') {
			await deleteProperty(data.property_uuid);
			await props.refresh();
			if (props.flyoutType === "locations") {
				await props.closeFlyout();
			} else if (props.flyoutType === "assemblies") {
				setEditMode(false);
				setIsNew(false);
				// blank our property data
				setData({ c3_additional_details: {} });
			}
		} else if (type === 'cancel') {
			setDeleteMode(false);
		} else {
			setDeleteMode(true);
			setTimeout(() => {
				document.getElementById('flyoutBase').scrollIntoView(false);
			}, 100);
		}
	};

	const handleDuplicateCheck = (ctx, payload) => {
		let conditionals = [];
		if (payload.property_uuid) {
			conditionals = [{ field: "property_uuid", value: payload.property_uuid, operator: "neq" }];
		}

		checkDuplicates('properties', payload.name, conditionals).then(response => {
			if (response.duplicates.length > 0) {
				ctx.setDuplicates(
					response.duplicates,
					[
						{ name: 'address', data: 'address1' },
						'city'
					],
					'/locations',
					payload,
					null,
					useSelectedDuplicate
				);
			}
		});
	}

	const reviewAction = async (type) => {
		if (type === 'accept') {
			await acceptProperty(data.property_uuid);
			getLocationData(props.uuid);
		} else if (type === 'reject') {
			await rejectProperty(data.property_uuid);
			getLocationData(props.uuid);
		}
	}

	const getLocationAssemblies = (uuid, page = null) => {
		const reportingParams = getDefaultReportingParams()
		reportingParams.search = { "property uuid": uuid };
		reportingParams.inputs = { "active": 'null' }
		reportingParams.count = 100
		if (page != null) {
			reportingParams.page = page
		}
		getEquipmentReport(reportingParams).then(response => {
			const { data, nextPage } = response
			const assembliesObject = data.rows.map((r) => reportRowToObject(r.values, data.outputs))
			if (page != null) {
				setLocationAssemblies( [...locationAssemblies, ...(assembliesObject || [])])
			} else {
				setLocationAssemblies( assembliesObject || [])
			}
			if (nextPage !== undefined && nextPage > reportingParams.page) {
				setAssemblyConfirmationPage(nextPage)
			} else {
				setAssemblyConfirmationPage(null)
			}
		});
	}

	const setLocationAsInactive = async () => {
		if (
			data.active ?
				confirm(`Are you sure you want to set the location "${data['name']}" as inactive?`) :
				confirm(`Are you sure you want to reactivate the location "${data['name']}" ?`)
		) {
			await editProperty(data['property_uuid'], {
				name: data['name'],
				active: data.active ? false : true
			});
			await props.refresh();
			await setEditMode(false);
			await getLocationData(props.uuid);
			if (data.active) {
				getLocationAssemblies(props.uuid)
			}
		} else {
			null;
		}
	}

	const handleLocationAssembliesConfirmationClose = () => {
		setLocationAssemblies(null)
	}

	const LocationAssembliesConfirmation = ({assemblies}) => {
		const [ loading, setLoading ] = useState(false)
		const switchHandler = (uuid, delta) => async () => {
			setLoading(true)
			await updateEquipment(uuid, {active: delta}).then(() => getLocationAssemblies(props.uuid)).catch(console.error).finally(() => setLoading(false))
		}

		const batchUpdate = async (delta) => {
			setLoading(true)
			await Promise.all(assemblies.filter((a) => a.active != delta).map((a) => updateEquipment(a.equipment_uuid, {active: delta === "true"}))).then(() => getLocationAssemblies(props.uuid)).catch(console.error).finally(() => setLoading(false))
		}

		return <Box style={{display: "flex", alignItems: "center", justifyContent: "center"}} p={4}>
			<FormGroup>
				{ loading ? <Loading/> :
				<>
					<Box style={{display: "flex", justifyContent: "center"}} pb={3}>
						<button className="medButtonPrimary" type="submit" onClick={() => batchUpdate("false")}>Inactivate All</button>
						<button className="medButtonPrimary" type="submit" onClick={() => batchUpdate("true")}>Activate All</button>
					</Box>
					{ assemblies.map((la) => {
						return <FormControlLabel
							key={la.equipment_uuid}
							control={<StyledSwitch onClick={switchHandler(la.equipment_uuid, la.active != "true")} checked={la.active === "true"} name={la.serial_number} value={la.equipment_uuid}/>}
							label={`Serial Number: ${la.serial_number}; Make: ${la.make}; Model: ${la.model} `}
						/>}
					)}
					{ assemblyConfirmationPage && <Box style={{display: "flex", justifyContent: "center"}} p={3}><button className="medButtonPrimary" type="submit" onClick={() => getLocationAssemblies(props.uuid, assemblyConfirmationPage)}>Load More</button></Box> }
				</>
				}
			</FormGroup>
		</Box>
	}
	// const handleUnlink = async (data) => {
	// 	if (props.flyoutType === "assemblies") {
	// 		await deleteEquipmentProperty(props.uuid, data.property_uuid);
	// 		setEditMode(false);
	// 		setIsNew(false);
	// 		// blank our property data
	// 		setData({ c3_additional_details: {} });
	// 		props.refresh();
	// 	}
	// }

	return (
		<ModalDuplicateContext.Consumer>
			{(ctx) => {
				return (
					<div className="flyoutContentContainer" id="flyoutBase">
						{ locationAssemblies != null && (
							<DialogContainer closeText="Done" title={`Adjust Activation Status of Assemblies${ locationAssemblies.length > 0 ? ` at ${locationAssemblies[0]?.location_name}` : ""}`} handleClose={handleLocationAssembliesConfirmationClose} open={true}>
								<>
									{ locationAssemblies.length === 0 ? <Box style={{textAlign: "center"}} p={4}><Typography variant="h3">No assemblies at this location</Typography></Box> : <LocationAssembliesConfirmation assemblies={locationAssemblies}/> }
								</>
							</DialogContainer>
						)}
						<button className="editModeButton" onClick={() => { props.flyoutType === 'assemblies' ? toggleEditMode('assembly') : toggleEditMode() }}>
							{!editMode && (
								<img src={editModeIcon} alt='' />
							)}
						</button>
						{data.c3_additional_details !== undefined && (
							<LocationDetailsForm
								data={data}
								context={ctx}
								editMode={editMode}
								saveDetails={saveDetails}
								toggleEditMode={toggleEditMode}
								newAddition={props.newAddition}
								closeFlyout={props.closeFlyout}
								apiErrors={apiErrors}
								duplicateCheck={handleDuplicateCheck}
								propertyContacts={propertyContacts}
								lastSurvey={lastSurvey}
							/>
						)}
						{editMode && !deleteMode && !props.newAddition && props.flyoutType !== 'assemblies' && (
							<div className="deleteButton__container">
								<button className="deleteButton" onClick={() => deleteThisLocation()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
							</div>
						)}
						{deleteMode && editMode &&
							<React.Fragment>
								<div className="flyout__deleteMode">
									<span>Warning: These actions cannot be undone.</span>
									<button className="medButtonSecondary" onClick={() => deleteThisLocation(data, 'delete')}>Delete Location</button>
									<button className="exit" onClick={() => deleteThisLocation(null, 'cancel')}><CloseIcon fontSize="large" /></button>
								</div>
							</React.Fragment>
						}
						{!props.newAddition && !editMode && (
							<div className="flexButtonContainer">
								{data.needs_review && (
									<button
										onClick={() => reviewAction('accept')}
										className="medButtonPrimary"
									>
										Accept Location
									</button>
								)}
								<button
									onClick={() => {
										window.location.replace(data.property_uuid ? `/surveys/newSurvey?location=${data.property_uuid}` : '/surveys/newSurvey');
									}}
									className="medButtonPrimary"
								>
									Add Survey
								</button>
							</div>
						)}
						{editMode && !props.newAddition && (
							<div className="flexButtonContainer" id="locationOptions">
								<div>
									<button className="medButtonSecondary" onClick={() => setLocationAsInactive()}>{data.active ? 'Set as Inactive' : 'Reactivate Location'}</button>
								</div>
							</div>
						)}
						<div className="overlaySecondary" id="overlaySecondary" onClick={() => { closeFlyout() }}></div>
						{flyoutActive && (
							<React.Fragment>
								<Flyout
									uuid={data.property_uuid}
									newAddition={false}
									flyoutType='locations'
									flyoutPriority="secondary"
									closeFlyout={closeFlyout}
									openFlyout={openFlyout}
									activeTab='locationDetails'
									refresh={props.refresh}
								/>
							</React.Fragment>
						)}
					</div>
				);
			}}
		</ModalDuplicateContext.Consumer>

	);
};

export default LocationDetails;
