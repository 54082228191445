import axios from "axios";
import { buildURLPath, getURLModule } from "../utils/url";
import { acceptedFiletypes } from "../utils/settings";
import { apiMocks } from "./_apiMocks";

// Custom error types to create and throw from our API calls
export class APIError extends Error {
    constructor(response, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, APIError);
        }

        this.name = "APIError";
        this.status = response.status;
        this.message = response.data.Error;
        this.response = response;
    }
}

export class APIUserError extends Error {
    constructor(response, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, APIUserError);
        }

        this.name = "APIUserError";
        this.status = response.status;
        this.message = response.data.Error;
        this.response = response;
        this.apiFieldErrors = {};
        response.data?.Details?.forEach((detail) => {
            // translate some api errors to frontend
            if (detail.description.match(/can not be an empty string/)) {
                detail.description = "This field can not be blank";
            }
            this.apiFieldErrors[detail.field] = detail.description;
        });
    }
}

// this is our CSRF token, yes it is effectively a global var, but I think that will end up being ok
let RequestToken = "";

// Here we setup any global axios settings we need.  Things like CSRF token handling, error handling, etc.
// If this is a development build, we also inject mock endpoints here
export const configureAxios = () => {
    if (process.env.NODE_ENV === "development") {
        axios.interceptors.request.use(
            (config) => {
                const mock = apiMocks.find((m) => m.method === config.method && m.url === config.url);
                return mock
                    ? Promise.reject({
                          mockData: mock.data ?? {},
                          mockHeaders: mock.headers ?? {},
                          mockStatus: mock.status ?? 200,
                          mockStatusText: mock.statusText ?? "OK",
                      })
                    : config;
            },
            (error) => Promise.reject(error)
        );

        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.mockData) {
                    const mockResponse = {
                        config: {},
                        request: {},
                        headers: error.mockHeaders,
                        data: error.mockData,
                        status: error.mockStatus,
                        statusText: error.mockStatusText,
                    };

                    if (mockResponse.status >= 400) {
                        // This is a mock error
                        return Promise.reject({ response: mockResponse });
                    }

                    // This is a mock success
                    return mockResponse;
                }

                // This is an actual error from the API
                return Promise.reject(error);
            }
        );
    }

    axios.interceptors.request.use((config) => {
        config.headers["x-scc3-request-token"] = RequestToken;
        config.headers["x-scc3-module"] = getURLModule().module;

        return config;
    });

    axios.interceptors.response.use(
        function (response) {
            let newRequestToken = response.headers["x-scc3-request-id"];
            if (newRequestToken) {
                RequestToken = newRequestToken;
            }
            return response;
        },
        function (error) {
            let newRequestToken = error.response.headers["x-scc3-request-id"];
            if (newRequestToken) {
                RequestToken = newRequestToken;
            }
            if (error.response.status == 401) {
                // 401 should mean they aren't logged in so redirect to login, add any path they were going to to the
                // whereTo param
                if (window.location.pathname == "" || window.location.pathname == "/") {
                    throw error;
                }
                let qsParams = {};
                let whereTo = window.location.pathname;
                if (window.location.search !== "") {
                    whereTo += window.location.search;
                }
                qsParams.whereTo = whereTo;
                let newPath = buildURLPath("/", qsParams);
                window.location.href = newPath;
                return;
            } else if (error.response.status >= 500 && error.response.status < 600) {
                // there was some backend issue, we should ask the user to try again and if the issue persists give them
                // an error with the request-id in it that they can they hand to support.  Long term we could tie it into
                // a support widget and have them submit a ticket from the app.
                document.getElementById("modal-error-message").innerText =
                    "There was an error with your request. Please try again. If this error persists, please contact support and reference the following error code:";
                document.getElementById("modal-error-reference").innerText =
                    error.response.headers["x-scc3-request-id"];
                document.getElementById("modal-error").classList.remove("hide");
                throw new APIError(error.response);
            } else if (error.response.status == 403) {
                document.getElementById("modal-error-message").innerText =
                    "Sorry, you do not have permission to perform that action.";
                document.getElementById("modal-error").classList.remove("hide");
                throw new APIError(error.response);
            } else if (error.response.status == 413) {
                document.getElementById("modal-error-message").innerText =
                    "File size too large. Please reduce the file size and try again.";
                document.getElementById("modal-error").classList.remove("hide");
                throw new APIUserError(error.response);
            } else if (error.response.status == 415) {
                document.getElementById("modal-error-message").innerText =
                    "Invalid file type. Please use one of the accepted file types (" +
                    acceptedFiletypes.join(", ") +
                    ").";
                document.getElementById("modal-error").classList.remove("hide");
                throw new APIUserError(error.response);
            } else if (error.response.status == 400) {
                // here they submitted something that was wrong, so we should parse the error / details in the response data
                // and provide them with all the issues they need to correct.
                throw new APIUserError(error.response);
            } else if (error.response.status == 408) {
                document.getElementById("modal-error-message").innerText =
                    "Request timed out.  Please wait a moment and try again.  If this error persists, please contact support and reference the following error code:";
                document.getElementById("modal-error-reference").innerText =
                    error.response.headers["x-scc3-request-id"];
                document.getElementById("modal-error").classList.remove("hide");
            }

            // if we haven't handled it yet, throw the error
            throw error;
        }
    );
};

// gets the /api/settings and stores them in our global var.  This isn't a "real" part of the API so going to go here
export const getSystemSettings = () => {
    return axios
        .get("/api/settings")
        .then((response) => {
            if (response) {
                // we don't know what all settings we want on the backend so it returns a map string -> string, so here
                // we just want to see if any of those values are boolean and "convert" them as such
                for (const [key, val] of Object.entries(response.data)) {
                    if (val === "true") {
                        SwiftComply.settings[key] = true;
                    } else if (val === "false") {
                        SwiftComply.settings[key] = false;
                    } else {
                        SwiftComply.settings[key] = val;
                    }
                }
            }
            return SwiftComply.settings;
        })
        .catch((error) => {
            throw error;
        });
};

// sends an error event to the backend for processing
export const recordWebError = (event, source, linenum, colnum) => {
    // don't try to record an error if we have no user, the recording of the error on the backend should only be for
    // authed users so this should always be set
    if (SwiftComply?.user?.user_uuid == "") {
        return;
    }
    return axios.post("/web/error", { event: event, source: source, linenum: linenum, colnum: colnum });
};
