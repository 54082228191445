import React from "react";
import { withRouter } from "react-router";
import { deleteContact, saveContactDetail, updateContact } from "../../../api/contactsAPI";
import { BasicContactFields } from "./BasicContactFields";
import { getURLParentObject } from "../../../utils/url";
import HomeForm from "../Home/HomeForm";
import { detailsReportMapping } from "../../../utils/reporting";

const EditContact = ({ preloadedBasicState, preloadedDetailsState, onEditComplete, onEditCancelled, history }) => {
    const { id: contactId, path: contactPath } = getURLParentObject("contacts") || {};

    const onSubmit = (basic, details) =>
        updateContact(contactId, basic)
            .then(() =>
                Promise.all(
                    Object.entries(details).map((detail) =>
                        saveContactDetail(contactId, {
                            form_field_uuid: detail[0],
                            value: typeof detail[1] === "undefined" ? undefined : `${detail[1]}`,
                        })
                    )
                )
            )
            .then(onEditComplete);

    const onDelete = () =>
        deleteContact(contactId).then(() => history.push(contactPath.split("/").slice(0, -1).join("/")));

    return (
        <HomeForm
            title="Edit Contact"
            basicFields={BasicContactFields}
            detailsFormName={detailsReportMapping.contact}
            onSubmit={onSubmit}
            onCancel={onEditCancelled}
            onDelete={onDelete}
            preloadedBasicState={preloadedBasicState}
            preloadedDetailsState={preloadedDetailsState}
        />
    );
};

export default withRouter(EditContact);
