import axios from "axios";

export const getAnalytesList = () => {
    const url = "/api/v1/analytes";

    return axios.get(url).then((response) => response.data);
};

export const createSampleReportConfig = (
    config_type,
    {
    reference_point_uuid,
    general_control_uuid,
    assigned_to,
    lab_report_required,
    custody_chain_required,
    recurrence,
    compliance_determinants,
    additional_requirements,
    report_due,
}) =>
    axios
        .post("/api/v1/analytes/sample_report_config", {
            reference_point_uuid,
            general_control_uuid: config_type === "specific_control" ? null : general_control_uuid,
            legal_control_uuid: null,
            lab_report_required,
            custody_chain_required,
            additional_requirements,
            compliance_determinants,
            assigned_to: assigned_to,
            recurrence_config: config_type === "recurring" ? recurrenceToRecurrenceConfig(recurrence) : null,
            ad_hoc_dates: config_type === "ad_hoc" ? [report_due] : null,
        })
        .then((response) => response.data);


export const getSampleReportConfig = (reportConfigID) =>
    axios.get(`/api/v1/analytes/sample_report_config/${reportConfigID}`).then((response) => {
        const { data } = response;
        const { recurrence_config, ...rest } = data || {};

        if (recurrence_config) {
            rest.report_schedule = "recurring";
            rest.recurrence = {
                frequency: recurrence_config.frequency.toLowerCase(),
                interval: `${recurrence_config.interval}`,
                start: recurrence_config.start,
                until: recurrence_config.until,
            };

            if (recurrence_config.frequency === "monthly_last_day") {
                recurrence_config.frequency = "monthly";
                recurrence_config.day_or_date = "last";
            } else if (recurrence_config.by_day_config) {
                rest.recurrence.day_or_date = "weekday";
                rest.recurrence.nth = `${recurrence_config.by_day_config.nth}`;
                rest.recurrence.day_of_week = recurrence_config.by_day_config.weekday;
                delete rest.by_day_config;
            } else {
                rest.recurrence.day_or_date = "date";
            }
        } else if (data.ad_hoc_dates?.length === 0) {
            rest.report_schedule = '';
            rest.report_due = '';
        } else {
            rest.report_schedule = "ad_hoc";
            rest.report_due = data.ad_hoc_dates?.[0];
        }

        return rest;
    });

export const updateSampleReportConfig = (
    reportConfigID,
    config_type,
    {
        reference_point_uuid,
        general_control_uuid,
        assigned_to,
        lab_report_required,
        custody_chain_required,
        recurrence,
        compliance_determinants,
        additional_requirements,
        report_due,
    }
) =>
    axios
        .put(`/api/v1/analytes/sample_report_config/${reportConfigID}`, {
            reference_point_uuid,
            general_control_uuid: config_type === "specific_control" ? null : general_control_uuid,
            legal_control_uuid: null,
            lab_report_required,
            custody_chain_required,
            additional_requirements,
            compliance_determinants,
            assigned_to: assigned_to,
            recurrence_config: config_type === "recurring" ? recurrenceToRecurrenceConfig(recurrence) : null,
            ad_hoc_dates: config_type === "ad_hoc" ? [report_due] : null,
        })
        .then((response) => response.data);

export const deleteSamplingReport = (reportConfigID) => {
    const url = `/api/v1/analytes/sample_report_config/${reportConfigID}`;

    return axios.delete(url).then((response) => response.data);
};

export const createSamplingConfig = ({
    sample_report_config_id,
    name,
    sample_type,
    min_aliquots,
    pacing,
    pacing_value,
    pacing_unit,
    lab_methods,
    container_types,
    container_volumes,
    preservatives,
    max_hold_time,
    notes,
    sampling_period,
    administrative_period,
    analyte,
    compliance_determinants,
}) => {
    if (!analyte?.length) {
        return Promise.reject({
            response: { data: { Details: [{ field: "analyte", description: "At least one analyte is required" }] } },
        });
    }

    return axios
        .post("/api/v2/analytes/sampling_config", {
            sample_report_config_id,
            name,
            sample_type,
            min_aliquots,
            pacing,
            pacing_value,
            pacing_unit,
            lab_methods,
            container_types,
            container_volumes,
            preservatives,
            max_hold_time,
            notes,
            sampling_period,
            administrative_period,
            analytes: analyte,
            compliance_determinants,
        })
        .then((response) => response.data);
};

// Get the body of a sampling config - this does not include the analytes
export const getSamplingConfig = (samplingConfigID) =>
    axios.get(`/api/v2/analytes/sampling_config/${samplingConfigID}`).then((response) => response.data);

export const updateSamplingConfig = ({
    samplingConfigId,
    sample_report_config_id,
    name,
    sample_type,
    min_aliquots,
    pacing,
    pacing_value,
    pacing_unit,
    lab_methods,
    container_types,
    container_volumes,
    preservatives,
    max_hold_time,
    notes,
    sampling_period,
    administrative_period,
    analyte,
    compliance_determinants,
}) => {
    if (!analyte?.length) {
        return Promise.reject({
            response: { data: { Details: [{ field: "analyte", description: "At least one analyte is required" }] } },
        });
    }

    return axios
        .put(`/api/v2/analytes/sampling_config/${samplingConfigId}`, {
            sample_report_config_id,
            name,
            sample_type,
            min_aliquots,
            pacing,
            pacing_value,
            pacing_unit,
            lab_methods,
            container_types,
            container_volumes,
            preservatives,
            max_hold_time,
            notes,
            sampling_period,
            administrative_period,
            analytes: analyte,
            compliance_determinants,
        })
        .then((response) => response.data);
};

export const deleteSamplingConfig = (samplingConfigId) => {
    const url = `/api/v2/analytes/sampling_config/${samplingConfigId}`;

    return axios.delete(url).then((response) => response.data);
};

const recurrenceToRecurrenceConfig = ({ frequency, interval, day_or_date, nth, day_of_week, start, until }) => ({
    frequency: day_or_date === "last" ? "monthly_last_day" : frequency,
    interval,
    start,
    until,
    by_day_config:
        frequency === "monthly"
            ? day_or_date === "weekday"
                ? {
                      nth,
                      weekday: day_of_week,
                  }
                : null
            : null,
    timezone: Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone || "Etc/UTC",
});

export const getRecurrences = ({ frequency, interval, day_or_date, nth, day_of_week, start, until }) =>
    axios
        .post(
            "/api/v1/analytes/recurrences",
            recurrenceToRecurrenceConfig({ frequency, interval, day_or_date, nth, day_of_week, start, until })
        )
        .then((response) => response.data);
