import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ClearRounded } from "@material-ui/icons";
import IconButton from "../../IconButton";

// This is just the Material-UI date picker with some custom props, wrapped in a component so it can be shared
//   by both <DateField> and <DateTimeField>
const DatePicker = ({ label, date, handleDateChange, disableFuture, error, disabled }) => {
    return (
        <KeyboardDatePicker
            InputProps={{
                endAdornment: !disabled && (
                    <IconButton icon={<ClearRounded />} onClick={() => handleDateChange(null, "")} />
                ),
            }}
            InputAdornmentProps={{ position: "start" }}
            disableToolbar
            disableFuture={disableFuture}
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="yyyy-MM-dd"
            label={label}
            value={date}
            onChange={handleDateChange}
            error={error}
            disabled={disabled}
        />
    );
};

export default DatePicker;
