/** @type {formField[]} */
export const BasicLimitFields = [
    {
        id: "analyte",
        label: "Analyte",
        inputType: "select",
        options: [],
        required: true,
    },
    { id: "min_value", label: "Min Value" },
    { id: "max_value", label: "Max Value" },
    {
        id: "units",
        label: "Limit Unit",
        inputType: "select",
        options: [
            { label: "Centum cubic feet", value: "Centum cubic feet" },
            { label: "Cubic feet", value: "Cubic feet" },
            { label: "Degrees C", value: "Degrees C" },
            { label: "Degrees F", value: "Degrees F" },
            { label: "Gallons", value: "Gallons" },
            { label: "Grams per liter", value: "Grams per liter" },
            { label: "Kilograms", value: "Kilograms" },
            { label: "Liters", value: "Liters" },
            { label: "Micrograms per liter", value: "Micrograms per liter" },
            { label: "Milligrams per liter", value: "Milligrams per liter" },
            { label: "Millions of gallons", value: "Millions of gallons" },
            { label: "Nanograms per liter", value: "Nanograms per liter" },
            { label: "Pounds", value: "Pounds" },
            { label: "Siemens / MHOs", value: "Siemens / MHOs" },
            { label: "SU", value: "SU" },
            { label: "Thousand gallons", value: "Thousand gallons" },
        ],
        required: true,
    },
    {
        id: "limit_type",
        label: "Limit Type",
        inputType: "select",
        options: [
            { label: "Instantaneous", value: "Instantaneous" },
            { label: "Daily Maximum", value: "Daily Maximum" },
            { label: "Daily Minimum", value: "Daily Minimum" },
            { label: "Instant Range (High-Low Values)", value: "Instant Range (High-Low Values)" },
            { label: "Daily Average", value: "Daily Average" },
            { label: "4 Day Consecutive Average", value: "4 Day Consecutive Average" },
            { label: "Weekly Average", value: "Weekly Average" },
            { label: "Monthly Average", value: "Monthly Average" },
            { label: "Annual Average", value: "Annual Average" },
            { label: "Daily Total", value: "Daily Total" },
            { label: "4 Day Consecutive Total", value: "4 Day Consecutive Total" },
            { label: "Weekly Total", value: "Weekly Total" },
            { label: "Monthly Total", value: "Monthly Total" },
            { label: "Annual Total", value: "Annual Total" },
            { label: "Split Sample Average", value: "Split Sample Average" },
        ],
        required: true,
    },
    {
        id: "definition",
        label: "Limit Definition",
        inputType: "select",
        options: [
            { label: "Compliance", value: "Compliance" },
            { label: "Notification", value: "Notification" },
        ],
        required: true,
    },
    {
        id: "rationale",
        label: "Limit Rationale",
        inputType: "select",
        options: [
            { label: "CFR", value: "CFR" },
            { label: "Local limits", value: "Local limits" },
            { label: "Authority (other)", value: "Authority (other)" },
            { label: "Surcharge", value: "Surcharge" },
            { label: "Notification", value: "Notification" },
            { label: "TRC", value: "TRC" },
        ],
        required: true,
    },
    {
        id: "general_control_label",
        label: "Connect to General Control",
        inputType: "label",
    },
    {
        id: "general_control",
        label: "Select General Control",
        inputType: "select",
        options: [], // This will be populated with fetched data
        toggle: {
            label: "",
            id: "use_general_control",
            behavior: "enable_field",
        },
    },
];
