import React from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import {
    updateSampleReportConfig,
    deleteSamplingReport,
} from "../../../api/analytesAPI";
import { BasicSamplingReportFields } from "./SamplingReportFields";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import HomeForm from "../../Common/Home/HomeForm";
import LoadingSpinner from "../../Common/LoadingSpinner";

const EditSamplingReport = ({ preloadedBasicState, onEditComplete, onEditCancelled, history }) => {
    const { id: samplingReportId } = getURLParentObject("samplingReports") || {};
    const { path: outfallPath } = getURLParentObject("outfalls") || {};
    const { generalControls, loading: generalControlLoading } = useGeneralControls();

    const updateReportConfig = (basic) => {
        const payload = {
            ...basic,
            general_control_uuid: basic.general_control ? basic.general_control : null,
        };
        if (!(basic.assigned_to === "property" && basic.general_control_type === "specific_control")) {
            if (basic.report_schedule === "recurring") {
                return updateSampleReportConfig(samplingReportId, 'recurring', payload);
            }
            return updateSampleReportConfig(samplingReportId, 'ad_hoc', payload);
        } else {
            return updateSampleReportConfig(samplingReportId, 'specific_control', payload);
        }
    };

    const onSubmit = (basic) => updateReportConfig(basic).then(onEditComplete);

    const onDelete = () => deleteSamplingReport(samplingReportId).then(() => history.push(outfallPath));

    if (generalControlLoading) {
        return <LoadingSpinner />;
    }

    const basicFields = BasicSamplingReportFields.map((f) =>
        f.id === "general_control" ? { ...f, options: generalControls } : f
    );

    const initialBasicState = {
        ...preloadedBasicState,
        general_control: preloadedBasicState.general_control_uuid || "",
        general_control_type: preloadedBasicState.general_control_uuid ? "general_control" : "specific_control",
    };

    return (
        <HomeForm
            title="Edit Sample Report Config"
            basicFields={basicFields}
            onSubmit={onSubmit}
            onCancel={onEditCancelled}
            onDelete={onDelete}
            preloadedBasicState={initialBasicState}
        />
    );
};

export default withRouter(EditSamplingReport);
