import React from "react";
import { getURLModule, getURLBaseSection } from "../../../utils/url";
import Home from "../../Common/Home/Home";
import AdvancedReportTable from "../../Common/ReportTable/AdvancedReportTable";

const IndustrialUsersHome = () => {
    const { slug } = getURLModule();
    const baseSection = getURLBaseSection();

    return (
        <Home title="Industrial Users" addNewFormName="addProperty" addNewButtonText="New Industry">
            <AdvancedReportTable
                reportUUID="2b408dd5-1df9-48be-8f28-b1e9cf8ea985"
                defaultColumns={["Name", "Address", "City", "State / Prov", "Postal Code", "Phone", "Discharge POTW"]}
                rowLinks={(row) => `/${slug}/${baseSection}/${row["Property UUID"]}`}
            />
        </Home>
    );
};

export default IndustrialUsersHome;
