import React from "react";
import { IconButton as MuiIconButton, Tooltip } from "@material-ui/core";
import styles from "../../styles/common/matieralUI/iconButton.module.css";

/**
 * Helper component to override the MaterialUI icon buttons with our styles
 *
 * @param {JSX.Element} icon - Icon component to use on the button
 * @param {Object} [tooltip] - Optional tooltip to display on hover
 * @param {string} tooltip.title - Text to display in the tooltip
 * @param {string} [tooltip.placement] - Placement of the tooltip
 * @param props - All other props are passed to the MaterialUI <IconButton>
 */
const IconButton = ({icon, tooltip, ...props}) => {
    const button = (
        <MuiIconButton {...props} classes={styles}>
            {icon}
        </MuiIconButton>
    );
    
    return (
        tooltip ? (
            <Tooltip title={<h1 style={{fontSize: "1.3rem"}}>{tooltip.title}</h1>} placement={tooltip.placement || 'top'} arrow>
                {button}
            </Tooltip>
        ) : button
    );
};

export default IconButton;
