import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import BasicReportTable from "../../Common/ReportTable/BasicReportTable";

const SamplingConfigDialog = ({ open, onClose, samplingReportUUID }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Sampling Configurations</DialogTitle>
            <DialogContent>
                <BasicReportTable
                    reportUUID="6a8542cb-6309-4da1-a06f-55958e4ca036"
                    reportingParams={{ inputs: { "Sampling Report UUID": samplingReportUUID } }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default SamplingConfigDialog;
