import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getURLModule } from "../../utils/url";
import { Capitalize } from "../../utils/misc";
import { Menu, MenuItem } from "@material-ui/core";
import styles from "../../styles/common/header.module.css";
import orgLogo from "../../../images/SwiftComplyRetina.png";
import headerLine from "../../../images/headerLine.svg";
import {
    ChevronRightRounded,
    ContactSupportRounded,
    ExpandMoreRounded,
    NotificationsRounded,
} from "@material-ui/icons";
import IconButton from "./IconButton";
import { logout } from "../../api/authAPI";
import { NavLink } from "react-router-dom";

const Header = () => {
    const [anchor, setAnchor] = useState(/** @type {Element} */ null);
    const { user } = useSelector((state) => state.swiftComply);
    const dropdownRef = useRef();
    const { module } = getURLModule();
    const moduleName = Capitalize(module);

    return (
        <div className={styles.header}>
            <div className={styles.content}>
                <div className={styles.leftGroup}>
                    <NavLink to="/pt/industrialUsers">
                        <img src={orgLogo} className={styles.orgLogo} alt="Swift Comply Logo" />
                    </NavLink>
                    <IconButton icon={<ChevronRightRounded />} />
                    <span>{moduleName}</span>
                </div>
                <div className={styles.rightGroup}>
                    <IconButton icon={<ContactSupportRounded />} />
                    <IconButton icon={<NotificationsRounded />} />
                    <img src={headerLine} alt="vertical line" />
                    <div className={styles.clickableGroup} onClick={() => setAnchor(dropdownRef.current)}>
                        <span>{`${user?.firstname} ${user?.lastname}`}</span>
                        <div className={styles.userIcon}>
                            <span>{user?.firstname?.charAt(0)}</span>
                        </div>
                        <IconButton icon={<ExpandMoreRounded ref={dropdownRef} />} />
                    </div>
                </div>
            </div>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                onClose={() => setAnchor(null)}
            >
                <MenuItem>Profile</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </div>
    );
};

export default Header;
