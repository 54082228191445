import React from "react";
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import styles from "../../../../styles/common/matieralUI/radio.module.css";

const RadioField = ({ label, options, value, setValue }) => {
    return (
        <div className={styles.wrapper}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{label}</FormLabel>
                <RadioGroup
                    classes={{ root: styles.root }}
                    value={value ?? options[0].value}
                    onChange={(_, newValue) => setValue(newValue)}
                >
                    {options.map((option) => (
                        <FormControlLabel key={option.value} control={<Radio />} {...option} />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default RadioField;
