import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { deleteSamplingConfig, getSampleReportConfig, updateSamplingConfig } from "../../../api/analytesAPI";
import useAnalytes from "../useAnalytes";
import HomeForm from "../../Common/Home/HomeForm";
import { getErrorText } from "../../../utils/errors";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { getSamplingConfigFields } from "./AddSamplingConfig";

const deleteText = (
    <>
        <span>Deleting this Sampling Configuration will delete all associated unsubmitted Sample Reports</span>
        <br />
        <span>Are you sure you want to continue?</span>
        <br />
        <span>This action cannot be undone!</span>
    </>
);

const EditSamplingConfig = ({ preloadedBasicState, onEditComplete, onEditCancelled, history }) => {
    const { id: samplingConfigId } = getURLParentObject("samplingConfig") || {};
    const { id: samplingReportId, path: samplingReportPath } = getURLParentObject("samplingReports") || {};
    const { analytes, error } = useAnalytes();
    const [isAuthority, setIsAuthority] = useState(false);

    useEffect(() => {
        getSampleReportConfig(samplingReportId)
            .then((data) => setIsAuthority(data.assigned_to === "organization"))
            .catch(console.error);
    }, []);

    const onSubmit = (basic) => {
        return updateSamplingConfig({
            ...basic,
            samplingConfigId,
            sample_report_config_id: samplingReportId,
            //the fields here have not yet been added to our basicSamplingConfigFields.js
            ...basic,
            sample_report_config_id: samplingReportId,
            name: `Sampling Config ${new Date().toISOString()}`, //API supports this field for now
            pacing_value: null,
            pacing_unit: null,
            administrative_period: null,
        }).then(onEditComplete);
    };

    const onDelete = () => deleteSamplingConfig(samplingConfigId).then(() => history.push(samplingReportPath));

    if (error) {
        return getErrorText(error);
    }

    if (!analytes.length) {
        return <LoadingSpinner />;
    }

    return (
        <HomeForm
            title="Edit Sampling Configuration"
            basicFields={getSamplingConfigFields(isAuthority, analytes)}
            onSubmit={onSubmit}
            onCancel={onEditCancelled}
            onDelete={onDelete}
            preloadedBasicState={preloadedBasicState}
            deleteText={deleteText}
        />
    );
};

export default withRouter(EditSamplingConfig);
