import React from "react";
import { FilterListRounded } from "@material-ui/icons";
import PopUpMenuForm from "../PopUpMenuForm";
import { Tooltip } from "@material-ui/core";
import styles from "../../../styles/common/reportTable.module.css";

const FilterMenu = ({ filterFields, selectedFilters, onApplyFilters }) => {
    return (
        <PopUpMenuForm
            icon={
                <Tooltip title={<h1 style={{fontSize: "1.3rem"}}>Table Filters</h1>} placement='top' arrow>
                    <FilterListRounded />
                </Tooltip>
            }
            title="Filters"
            formFields={filterFields}
            initialFormState={selectedFilters}
            onApply={onApplyFilters}
        />
    );
};

export default FilterMenu;
