import React from "react";
import { withRouter } from "react-router";
import { createGeneralControl } from "../../../api/permitsAPI";
import { getURLModule } from "../../../utils/url";
import { BasicGeneralControlFields } from "./BasicGeneralControlFields";
import HomeForm from "../../Common/Home/HomeForm";

const AddGeneralControl = ({ title, history }) => {
    const { slug } = getURLModule();

    const onSubmit = (basic) => {
        return createGeneralControl(basic).then((result) => {
            history.push(`/${slug}/config/generalControls`);
        });
    };

    return (
        <HomeForm
            title={title || "Create General Control"}
            basicFields={BasicGeneralControlFields}
            onSubmit={onSubmit}
            onCancel={() => history.goBack()}
        />
    );
};

export default withRouter(AddGeneralControl);
