import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { stateProvLists } from "../../../utils/stateProvLists";
import styles from "../../../styles/common/form.module.css";
import InputField from "./Fields/InputField";
import SelectField from "./Fields/SelectField";
import RadioField from "./Fields/RadioField";
import CheckboxField from "./Fields/CheckboxField";
import TextAreaField from "./Fields/TextAreaField";
import DateField from "./Fields/DateField";
import MultiSelectField from "./Fields/MultiSelectField";
import IntervalField from "./Fields/IntervalField";
import DateTimeField from "./Fields/DateTimeField";
import Snackbar from "../Snackbar";
import RecurrenceField from "./Fields/RecurrenceField";

/**
 * An object that defines a field toggle
 *
 * @typedef {Object} formFieldToggle
 * @property {string} label - Text displayed on the toggle
 * @property {string} id - The id of associated form field (or API field)
 * @property {"add_to_array"|"enable_field"} behavior - What the toggle actually does
 * @property {(formState:Object) => boolean} [hideWhen] - Callback function that hides the toggle if returns true
 */

/**
 * An object that defines a field on a form
 *
 * @typedef {Object} formField
 * @property {string} id - The name of the field (property) in the formState object
 * @property {string} label - The field name displayed on the form
 * @property {string} [inputType] - The type of control to use (default is "input")
 * @property {string} [dataType] - The type of data in the field (default is "text")
 * @property {string[]} [suggestions] - Array of suggestion values shown as the user is typing
 * @property {{label:string, value:string}[]} [options] - Array of options used to create a drop-down list
 * @property {boolean} [required] - True if this is a required field
 * @property {boolean} [disabled] - Set to disable the form component for this field
 * @property {formFieldToggle} [toggle] - If set, a form will display a "toggle" checkbox along with the input component
 * @property {(formState:Object) => boolean} [hideWhen] - Callback function that hides the component if returns true
 * @property {*} [defaultValue] - Default value
 */

/**
 * Renders a form with the specified fields, updates state based on user input
 * - Note: does not handle form submission, this is left to the parent component
 *
 * @param {formField[]} fields - Array of objects defining the fields to be rendered
 * @param {Object} formState - The current value of all fields
 * @param {(Object) => void} onFormStateChange - Called every time any field's value changes
 * @param {{id:string, error:string}[]} [validationErrors] - Array of errors to be displayed on each field
 * @param {{message:string|JSX.Element, severity?:string, duration?:number}} [generalError]
 *    - If present, will be displayed as a snackbar message above the form
 */
const Form = ({ fields, formState, onFormStateChange, validationErrors = [], generalError }) => {
    const { organization } = useSelector((state) => state.swiftComply);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const errorRef = useRef();

    // Some API fields allow "" as a value but this should be treated as "empty" by the UI
    const isEmpty = (value) => typeof value === "undefined" || value === "" || (Array.isArray(value) && !value.length);

    useEffect(() => {
        if (generalError?.message) {
            setSnackbarMessage(generalError);
            return;
        }

        if (validationErrors.length) {
            setSnackbarMessage({ message: "Form submission failed. Please correct the errors and try again." });
            errorRef?.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [JSON.stringify(validationErrors), generalError?.message]);

    const renderInputWithToggle = (field) => (
        <div className={styles.toggleWrapper}>
            <div className={styles.toggledInput}>{renderInput(field)}</div>
            {!field.toggle.hideWhen?.(formState) && (
                <div data-testid={`${field.id}-toggle`}>
                    <CheckboxField
                        disabled={field.toggle.behavior !== "enable_field" && isEmpty(formState[field.id])}
                        type="switch"
                        label={<span className={styles.toggleText}>{field.toggle.label}</span>}
                        value={getToggle(field.id)}
                        setValue={(checked) => setToggle(field.id, checked)}
                    />
                </div>
            )}
        </div>
    );

    /**
     * Convert a formField object to a JSX element to render on the form
     *
     * @param {formField} field
     * @returns {JSX.Element}
     */
    const renderInput = (field) => {
        const baseProps = {
            label: field.label,
            value: formState[field.id] ?? field.value, // Use the value from formState or the default value from the field
            setValue: (val) => {
                onFormStateChange({ ...formState, [field.id]: val });

                if (field.toggle && field.toggle.behavior !== "enable_field" && isEmpty(val)) {
                    setToggle(field.id, false);
                }
            },
            disabled: field.disabled || (field.toggle?.behavior === "enable_field" && !getToggle(field.id)),
            error: validationErrors?.find((err) => err.id === field.id)?.error || "",
        };

        if (field.required) {
            baseProps.label += " *";
        }

        switch (field.inputType) {
            case "label":
                return (
                    <span className={styles.formLabel} data-testid={field.id}>
                        {field.label}
                    </span>
                );

            default:
            case "input":
                if (field.dataType === "numeric") {
                    return <InputField {...baseProps} type="number" />;
                }

                return <InputField {...baseProps} suggestions={field.suggestions} />;

            case "select":
                return <SelectField {...baseProps} options={field.options} />;

            case "state_prov_select":
                return (
                    <SelectField
                        {...baseProps}
                        options={stateProvLists[organization?.country_code?.toLocaleUpperCase() ?? "US"]?.map((s) => ({
                            label: s.name,
                            value: s.abbrev,
                        }))}
                    />
                );

            case "radio":
                return <RadioField {...baseProps} options={field.options} />;

            case "checkbox":
                return <CheckboxField {...baseProps} />;

            case "textarea":
                return <TextAreaField {...baseProps} />;

            case "date":
                return <DateField {...baseProps} />;

            case "date_time":
                return <DateTimeField {...baseProps} timeLabel={field.timeLabel || "Time"} />;

            case "past_date":
                return <DateField {...baseProps} disableFuture />;

            case "multi":
                return <MultiSelectField {...baseProps} options={field.options} />;

            case "interval":
                return <IntervalField {...baseProps} />;

            case "recurrence":
                return <RecurrenceField {...baseProps} />;

            case "recurrence_with_preview":
                return <RecurrenceField {...baseProps} showDates />;

            // TODO: handle "custom:x" fields for special details fields in Backflow
        }
    };

    const setToggle = (fieldID, checked) =>
        onFormStateChange((prev) => {
            const field = fields.find((f) => f.id === fieldID);
            const toggleID = field.toggle.id;

            if (field.toggle.behavior === "enable_field") {
                return {
                    ...prev,
                    [toggleID]: checked,
                    [fieldID]: checked ? prev[fieldID] : null,
                };
            } else {
                // "add_to_array" behavior
                return {
                    ...prev,
                    [toggleID]: checked
                        ? [...new Set([...(prev[toggleID] || []), fieldID])]
                        : (prev[toggleID] || []).filter((val) => val !== fieldID),
                };
            }
        });

    const getToggle = (fieldID) => {
        const field = fields.find((f) => f.id === fieldID);
        const toggleID = field.toggle.id;

        if (field.toggle.behavior === "enable_field") {
            return formState[toggleID] ?? false;
        } else {
            // "add_to_array" behavior
            return formState[toggleID]?.indexOf(fieldID) >= 0 ?? false;
        }
    };

    return (
        <>
            <div className={styles.formWrapper}>
                {fields
                    .filter((f) => !f.hideWhen?.(formState))
                    .map((f) => (
                        <div
                            className={styles.fieldWrapper}
                            key={`${f.id}-input`}
                            ref={f.id === validationErrors[0]?.id ? errorRef : null}
                        >
                            {f.toggle ? renderInputWithToggle(f) : renderInput(f)}
                        </div>
                    ))}
            </div>
            {/* The message needs to be reset when closed, otherwise the same message won't show a second time */}
            <Snackbar {...snackbarMessage} onClose={() => setSnackbarMessage({ message: "" })} />
        </>
    );
};

export default Form;
