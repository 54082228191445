import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { createSamplingConfig, getSampleReportConfig } from "../../../api/analytesAPI";
import { getErrorText } from "../../../utils/errors";
import { BasicSamplingConfigFields } from "./BasicSamplingConfigFields";
import useAnalytes from "../useAnalytes";
import LoadingSpinner from "../../Common/LoadingSpinner";
import HomeForm from "../../Common/Home/HomeForm";
import Button from "../../Common/Button";

// Remove compliance toggles if parent report config is assigned to authority
// Add analyte list to "analyte" field options
export const getSamplingConfigFields = (isAuthority, analytes) =>
    BasicSamplingConfigFields.map((f) => {
        const { toggle, ...rest } = f;

        return {
            ...rest,
            toggle: isAuthority ? undefined : toggle,
            options: f.id === "analyte" ? analytes : f.options,
        };
    });

const AddSamplingConfig = ({ history }) => {
    const { id: samplingReportId, path: samplingReportPath } = getURLParentObject("samplingReports") || {};
    const { analytes, error } = useAnalytes();
    const [formKey, setFormKey] = useState(0);
    const [isAuthority, setIsAuthority] = useState(false);

    useEffect(() => {
        // Get parent sampling report to determine whether this is industry or authority
        getSampleReportConfig(samplingReportId)
            .then((data) => setIsAuthority(data.assigned_to === "organization"))
            .catch(console.error); // fail quietly, just means that the toggles won't be hidden
    }, []);

    const createConfig = (basic) => {
        return createSamplingConfig({
            ...basic,
            sample_report_config_id: samplingReportId,
            //the fields here have not yet been added to our basicSamplingConfigFields.js
            name: `Sampling Config ${new Date().toISOString()}`, //API supports this field for now
            pacing_value: null,
            pacing_unit: null,
            administrative_period: null,
            compliance_determinants: basic.compliance_determinants || [],
        });
    };

    const onSubmit = (basic) => createConfig(basic).then(() => history.push(samplingReportPath));

    const onSaveAndCreateAnother = (basic) =>
        createConfig(basic).then(() => {
            setFormKey((prevKey) => prevKey + 1); // Force HomeForm to reset
            history.push(`${samplingReportPath}/addSamplingConfig`);
        });

    const customButton = (doSave) => (
        <Button onClick={() => doSave(onSaveAndCreateAnother)}>Save and Create Another Sampling Configuration</Button>
    );

    if (error) {
        return getErrorText(error);
    }

    if (!analytes.length) {
        return <LoadingSpinner />;
    }

    return (
        <HomeForm
            key={formKey}
            title="Add Sampling Configuration"
            basicFields={getSamplingConfigFields(isAuthority, analytes)}
            onSubmit={onSubmit}
            onCancel={() => history.push(samplingReportPath)}
            customButton={customButton}
            preloadedBasicState={{ sample_schedule: "on_date" }}
        />
    );
};

export default withRouter(AddSamplingConfig);
