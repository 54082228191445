import React, { useState } from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { createLimit } from "../../../api/referencePointsAPI";
import { getErrorText } from "../../../utils/errors";
import { BasicLimitFields } from "./LimitFields";
import useAnalytes from "../useAnalytes";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import HomeForm from "../../Common/Home/HomeForm";
import LoadingSpinner from "../../Common/LoadingSpinner";

const AddLimit = ({ history }) => {
    const { id: outfallId, path: outfallPath } = getURLParentObject("outfalls") || {};
    const { analytes, error: analyteError, loading: analyteLoading } = useAnalytes();
    const { generalControls, error: generalControlError, loading: generalControlLoading } = useGeneralControls();

    const onSubmit = (basic) => {
        const payload = { ...basic, calculation: "none" };

        if (payload.use_general_control && payload.general_control) {
            payload.general_control_uuid = payload.general_control;
        }

        // Remove unnecessary fields
        delete payload.use_general_control;
        delete payload.general_control;

        return createLimit(outfallId, payload).then(() => history.push(outfallPath));
    };

    if (analyteError || generalControlError) {
        return getErrorText(analyteError || generalControlError);
    }

    if (analyteLoading || generalControlLoading) {
        return <LoadingSpinner />;
    }

    const basicFields = BasicLimitFields.map((f) =>
        f.id === "analyte"
            ? { ...f, options: analytes }
            : f.id === "general_control"
            ? { ...f, options: generalControls }
            : f
    );

    return (
        <HomeForm
            title="Add Limit"
            basicFields={basicFields}
            onSubmit={onSubmit}
            onCancel={() => history.goBack()}
            preloadedBasicState={{
                use_general_control: false,
                general_control: null,
            }}
        />
    );
};

export default withRouter(AddLimit);
