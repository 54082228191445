import React from 'react';
import serviceProvider from '../../../images/serviceProviderBlue.svg';
import Flyout from '../Flyout';
import validator from 'validator';
import { updateURL } from '../../utils/url';
import serviceProviderLarge from '../../../images/serviceProviderBlue.svg';
import { hasRole } from '../../api/permissionsAPI';
import ReportTable from '../ReportTable/ReportTable';

class ServiceProvidersDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			flyoutActive: false,
			serviceProviderInfo: {},
			newServiceProvider: false,
			serviceProviderUUID: null,
			isOrgAdmin: false
		};

		this.table = React.createRef();

		let urlParts = this.props.location.pathname.split("/");
		if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
			this.state.serviceProviderUUID = urlParts[2];
		}
	}

	async componentDidMount() {
		if (this.state.serviceProviderUUID !== null) {
			this.openFlyout({ service_provider_uuid: this.state.serviceProviderUUID }, false);
		}

		hasRole("Org.Admin").then(hasRole => {
			if (hasRole) {
				this.setState(() => ({ isOrgAdmin: true }))
			}
		})
	}

	openFlyout = async (serviceProviderInfo, newServiceProvider) => {
		const overlay = document.getElementById('overlay');

		if (newServiceProvider) {
			this.setState({ serviceProviderInfo: {}, newServiceProvider: true, flyoutActive: true });
		} else {
			serviceProviderInfo = {
				service_provider_uuid: serviceProviderInfo.service_provider_uuid
			};
			this.setState({ serviceProviderInfo, newServiceProvider: false, flyoutActive: true });
			// update the url in the browser without causing any reloads
			updateURL('/serviceProviders/' + serviceProviderInfo.service_provider_uuid, {});
		}
		overlay.classList.add('overlay__visible');
	}

	closeFlyout = () => {
		const flyout = document.getElementById('flyout');
		const overlay = document.getElementById('overlay');

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlay__visible');

		setTimeout(() => {
			this.setState(() => ({
				flyoutActive: false,
				serviceProviderInfo: {},
				serviceProviderUUID: null
			}));
		}, 200);

		this.table.current.ParentEventHandler('flyout closed', { path: '/serviceProviders' });
	}

	render() {
		const { flyoutActive, serviceProviderInfo, newServiceProvider, isOrgAdmin } = this.state;
		return (
			<div className="componentContainer">
				<div className="componentHeader">
					<div className="flexAlignCenter">
						<img src={serviceProvider} alt='' />
						<h3>Service Providers</h3>
					</div>
					{SwiftComply.user.user_type === 'normal' && (
						<button onClick={() => this.openFlyout(null, true)} className="longButtonPrimary">Add Service Provider</button>
					)}
				</div>
				<ReportTable
					ref={this.table}
					page='serviceproviders'
					tableType='Service Providers'
					openFlyout={this.openFlyout}
					flyoutActive={flyoutActive}
					defaultReportIdx='0'
					reports={[
						{
							reportUUID: "0a3d6a20-46df-47b9-9321-a8cc9a10bb75",
							name: "Service Providers",
							columns: ['Name', 'Address', 'City', 'Website']
						}
					]}
				/>
				<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={serviceProviderLarge} alt='' />
				<div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }}>
				</div>
				{flyoutActive && (
					<React.Fragment>
						<Flyout
							uuid={serviceProviderInfo.service_provider_uuid}
							newAddition={newServiceProvider}
							isOrgAdmin={isOrgAdmin}
							flyoutType='serviceProviders'
							flyoutPriority='primary'
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='serviceProviderDetails'

							refresh={() => this.table.current.ParentEventHandler('needs update')}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default ServiceProvidersDashboard;