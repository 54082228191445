import React from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { createSampleReportConfig } from "../../../api/analytesAPI";
import { BasicSamplingReportFields } from "./SamplingReportFields";
import { getErrorText } from "../../../utils/errors";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import HomeForm from "../../Common/Home/HomeForm";
import LoadingSpinner from "../../Common/LoadingSpinner";
import Button from "../../Common/Button";

const AddSamplingReport = ({ history }) => {
    const { id: outfallId, path: outfallPath } = getURLParentObject("outfalls") || {};
    const { generalControls, error: generalControlError, loading: generalControlLoading } = useGeneralControls();

    const createReportConfig = (basic) => {
        const payload = {
            ...basic,
            reference_point_uuid: outfallId,
            general_control_uuid: basic.general_control ? basic.general_control : null,
        };
        if (!(basic.assigned_to === "property" && basic.general_control_type === "specific_control")) {
            if (basic.report_schedule === "recurring") {
                return createSampleReportConfig('recurring', payload);
            }
            return createSampleReportConfig('ad_hoc', payload);
        } else {
            return createSampleReportConfig('specific_control', payload);
        }
    };

    const onSubmit = (basic) =>
        createReportConfig(basic).then((response) =>
            history.push(`${outfallPath}/samplingReports/${response.sample_report_config_id}`)
        );

    const onSaveAndAddSamplingConfig = (basic) =>
        createReportConfig(basic).then((response) =>
            history.push(`${outfallPath}/samplingReports/${response.sample_report_config_id}/addSamplingConfig`)
        );

    const customButton = (doSave) => (
        <Button onClick={() => doSave(onSaveAndAddSamplingConfig)}>Save and Add Sampling Configuration</Button>
    );

    if (generalControlError) {
        return getErrorText(generalControlError);
    }

    if (generalControlLoading) {
        return <LoadingSpinner />;
    }

    const basicFields = BasicSamplingReportFields.map((f) =>
        f.id === "general_control" ? { ...f, options: generalControls } : f
    );

    return (
        <HomeForm
            title="Add Sample Report Config"
            basicFields={basicFields}
            onSubmit={onSubmit}
            onCancel={() => history.goBack()}
            preloadedBasicState={{
                report_schedule: "ad_hoc",
                general_control: null,
            }}
            customButton={customButton}
        />
    );
};

export default withRouter(AddSamplingReport);
