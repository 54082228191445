import React, { useState } from "react";
import { FormControl, FormHelperText } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { dateToAPIString } from "../../../../utils/forms";
import DateFnsUtils from "@date-io/date-fns";
import styles from "../../../../styles/common/matieralUI/datePicker.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";
import DatePicker from "./DatePicker";

const DateField = ({ label, disableFuture, value, setValue, error, disabled }) => {
    // The API expects a string value containing a timestamp in seconds, e.g. "1700000000", but the <KeyboardDatePicker>
    // will crash if the displayed value is anything other than a Date object or null, so value must be converted on
    // every input event
    let millisecondsValue = value ? parseInt(value) : 0;

    // Have to make an assumption about whether this value is seconds or milliseconds (this could definitely be wrong)
    // A value of "100000000000" is either Mar 3, 1973 (milliseconds) or Nov 16, 5138 (seconds), so use this as the
    // cut-off value; larger values are assumed milliseconds, smaller values are assumed seconds
    if (millisecondsValue < 1e11) {
        // Date() constructor expects a value in milliseconds
        millisecondsValue *= 1000;
    }

    // The value used internally by the <KeyboardDatePicker> object
    const [date, setDate] = useState(value ? new Date(millisecondsValue) : null);

    // Both the internal value (a Date object) and the form state value (a string containing a number of seconds)
    // must be updated whenever the user makes a change
    const handleDateChange = (newDate, newDateString) => {
        if (!newDateString) {
            // Nothing entered, set date to null to avoid MUI crash, set parent state to undefined
            setDate(null);
            setValue(undefined);
            return;
        }

        if (isNaN(newDate)) {
            // Some text has been entered but it is not a valid date, update the control but not the parent state
            setDate(newDate);
            setValue(undefined);
            return;
        }

        // Valid date, update both states
        setDate(newDate);
        setValue(dateToAPIString(newDate));
    };

    return (
        <FormControl error={!!error}>
            <div className={styles.wrapper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        label={label}
                        date={date}
                        handleDateChange={handleDateChange}
                        disableFuture={disableFuture}
                        disabled={disabled}
                    />
                </MuiPickersUtilsProvider>
            </div>
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default DateField;
