import React from "react";
import { withRouter } from "react-router";
import { createContact, saveContactDetail } from "../../../api/contactsAPI";
import { getURLModule, getURLParentObject } from "../../../utils/url";
import { BasicContactFields } from "./BasicContactFields";
import { createPropertyContact } from "../../../api/propertiesAPI";
import { checkDuplicates } from "../../../api/duplicatesAPI";
import { detailsReportMapping } from "../../../utils/reporting";
import HomeForm from "../Home/HomeForm";

const AddContact = ({ history }) => {
    const { module, slug } = getURLModule();
    const { id: propertyId, path: propertyPath } = getURLParentObject("industrialUsers") || {};

    // The form has been submitted, return to the page that opened the form
    const exitForm = (contactUUID) => {
        if (propertyId) {
            // Return to the linked property when done
            history.push(propertyPath);
        } else {
            // This is an unlinked contact, return to the new contact
            history.push(`/${slug}/contacts/${contactUUID}`);
        }
    };

    // Link the contact to the current property (if one exists) and exit
    const linkAndExit = (contactUUID) => {
        if (propertyId) {
            return createPropertyContact(propertyId, contactUUID, {}).then(() => exitForm(contactUUID));
        }
        exitForm(contactUUID);
    };

    // Make the API call to save the contact, link it, and exit
    const onSubmit = (basic, details) => {
        let contactUUID = null;

        return createContact(basic)
            .then((result) => {
                contactUUID = result.contact_uuid;

                return Promise.all(
                    Object.entries(details).map((detail) =>
                        saveContactDetail(contactUUID, {
                            form_field_uuid: detail[0],
                            value: typeof detail[1] === "undefined" ? undefined : `${detail[1]}`,
                        })
                    )
                );
            })
            .then(() => linkAndExit(contactUUID));
    };

    // TODO: Implement accurate duplicate checking based on required fields
    // const onCheckDuplicates = (basic) =>
    //     checkDuplicates("contacts", `${basic.firstname} ${basic.lastname}`).then((response) =>
    //         (response.duplicates || []).map((d) => ({
    //             label: [d.value, d.additional_fields?.email, d.additional_fields?.phone].filter(Boolean).join(", "),
    //             value: d.uuid,
    //         }))
    //     );

    return (
        <HomeForm
            title="Add Contact"
            basicFields={BasicContactFields}
            detailsFormName={detailsReportMapping.contact}
            onSubmit={onSubmit}
            onCancel={() => history.goBack()}
            // TODO: Reimplement duplicate checking after M1 launch
            // onCheckDuplicates={onCheckDuplicates}
            // onUseDuplicate={linkAndExit}
        />
    );
};

export default withRouter(AddContact);
