import React, { useEffect, useState } from "react";
import { getErrorText } from "../../../utils/errors";
import { defaultColumnFormats } from "../../../utils/columnFormats";
import { AddRounded, ClearRounded } from "@material-ui/icons";
import styles from "../../../styles/common/multiStepFormReportTable.module.css";
import LoadingSpinner from "../LoadingSpinner";
import SamplingConfigDialog from "../../Pretreatment/Permits/SamplingConfigDialog";

/**
 * Renders a simple report table for multi-step forms (no search, filters, or column selection)
 *
 * @param {string} reportUUID - The report to be fetched
 * @param {string[]} [visibleColumns] - Array of columns that are shown in the table
 * @param {Object} [columnFormats] - Object containing functions called when rendering each cell, can be used to alter contents
 * @param {Object} formState - The current form state
 * @param {Function} onFormStateChange - Callback function to update the form state in the parent component
 * @param {Object} tableSelections - Object containing table selection state
 * @param {Function} setTableSelections - Function to update table selection state
 * @param {Object} columnConfig - Object containing column configuration for table rendering
 * @param {boolean} showSamplingConfigDialog - Flag to control the visibility of the sampling config dialog
 * @param {Function} setShowSamplingConfigDialog - Function to update the visibility of the sampling config dialog
 * @param {Function} fetchAndSetReportData - Function to fetch and set report data
 * @param {Object} templateData - Template data for the report
 * @param {string} tableKey - Key to identify the table
 */
const MultiStepFormReportTable = ({
    reportUUID,
    visibleColumns,
    columnFormats,
    formState,
    onFormStateChange,
    tableSelections,
    setTableSelections,
    columnConfig,
    showSamplingConfigDialog,
    setShowSamplingConfigDialog,
    fetchAndSetReportData,
    templateData,
    tableKey,
}) => {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [samplingReportUUID, setSamplingReportUUID] = useState(null);

    const reportObject = templateData?.reports?.find(
        (report) => report.report_uuid === reportUUID && report.key === tableKey
    );

    useEffect(() => {
        if (!formState[reportUUID]?.[tableKey] && templateData) {
            fetchAndSetReportData(reportUUID, formState.industrial_user, tableKey);
        }
    }, [reportUUID, formState.industrial_user, templateData, fetchAndSetReportData]);

    useEffect(() => {
        if (formState[reportUUID]?.[tableKey]) {
            setData(formState[reportUUID][tableKey]);
        }
    }, [formState, reportUUID, tableKey]);

    // Check if a column should be visible based on the visibleColumns prop and exclude UUID columns
    const isColumnVisible = (col) => !col.match(/uuid/i) && (!visibleColumns?.length || visibleColumns.includes(col));

    // Filter out UUID columns from the outputs array
    const filteredOutputs = data?.outputs?.filter((output) => isColumnVisible(output.name));

    // Toggle the selection state of a row and update the tableSelections
    const toggleSelection = (row) => {
        const rowIndex = data?.rows?.findIndex((r) => r.values.join("-") === row.values.join("-"));
        setTableSelections((prevSelections) => {
            const updatedSelections = [...(prevSelections[tableKey] || [])];
            updatedSelections[rowIndex] = !updatedSelections[rowIndex];

            const updatedFormState = {
                ...formState,
                [tableKey]: {
                    ...formState[tableKey],
                    selectedRows: data?.rows
                        ?.map((r, index) => (updatedSelections[index] ? index : null))
                        .filter((index) => index !== null),
                },
            };

            onFormStateChange(updatedFormState);

            return {
                ...prevSelections,
                [tableKey]: updatedSelections,
            };
        });
    };

    const handleAnalyteClick = (samplingReportUUID) => {
        setSamplingReportUUID(samplingReportUUID);
        setShowSamplingConfigDialog(true);
    };

    if (error) {
        return getErrorText(error);
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className={styles.scrollableWrapper}>
            <h3 className={styles.tableHeader}>{reportObject?.label || data?.name}</h3>
            {data?.rows?.length > 0 ? (
                <div className={styles.tableWrapper}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {reportObject.table_type !== "grid-table" && (
                                    <th className={styles.selectionColumn}></th>
                                )}
                                {filteredOutputs?.map((output) => (
                                    <th key={output.name}>{output.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className={styles.tableBody}>
                            {reportObject.table_type === "grid-table"
                                ? // Grid table logic
                                  formState.industrial_user && data?.rows
                                    ? data?.rows
                                          .filter((row) => row.values[0] === formState.industrial_user)
                                          .map((row, i) => (
                                              <tr key={`row-${i}`}>
                                                  {filteredOutputs?.map((output) => {
                                                      const { name, type } = output;
                                                      const columnIndex = data?.outputs?.findIndex(
                                                          (o) => o.name === name
                                                      );
                                                      const value = row.values[columnIndex];
                                                      const formatFunction =
                                                          columnFormats?.[name] ?? defaultColumnFormats[type]?.format;

                                                      return (
                                                          <td key={`row-${i}-${name}`}>
                                                              <div>
                                                                  {formatFunction ? formatFunction(value) : value}
                                                              </div>
                                                          </td>
                                                      );
                                                  })}
                                              </tr>
                                          ))
                                    : null
                                : // Other table types logic
                                  data?.rows?.map((row, i) => {
                                      const isSelected = tableSelections[tableKey]?.[i] || false;

                                      return (
                                          <tr key={`row-${i}`} className={isSelected ? styles.selected : ""}>
                                              {reportObject.table_type !== "grid-table" && (
                                                  <td className={styles.selectionColumn}>
                                                      <div
                                                          className={styles.selectionIcon}
                                                          onClick={(e) => {
                                                              e.stopPropagation();
                                                              toggleSelection(row);
                                                          }}
                                                      >
                                                          {isSelected ? (
                                                              <ClearRounded className={styles.icon} />
                                                          ) : (
                                                              <AddRounded className={styles.icon} />
                                                          )}
                                                      </div>
                                                  </td>
                                              )}
                                              {filteredOutputs?.map((output) => {
                                                  const { name, type } = output;
                                                  const columnIndex = data?.outputs?.findIndex((o) => o.name === name);
                                                  const value = row.values[columnIndex];
                                                  const formatFunction =
                                                      columnFormats?.[name] ?? defaultColumnFormats[type]?.format;
                                                  const config = columnConfig[name] || {};

                                                  return (
                                                      <td key={`row-${i}-${name}`}>
                                                          {config.render ? (
                                                              config.render({
                                                                  value,
                                                                  isSelected,
                                                                  samplingReportUUID:
                                                                      row.values[
                                                                          data?.outputs?.findIndex(
                                                                              (o) => o.name === "Sampling Report UUID"
                                                                          )
                                                                      ],
                                                                  handleAnalyteClick: handleAnalyteClick,
                                                              })
                                                          ) : (
                                                              <div>
                                                                  {formatFunction ? formatFunction(value) : value}
                                                              </div>
                                                          )}
                                                      </td>
                                                  );
                                              })}
                                          </tr>
                                      );
                                  })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={styles.noRowsFound}>No Rows Found</div>
            )}
            {reportUUID === "f72b5a28-0924-4255-a0fd-eb23c1b136d7" && (
                <SamplingConfigDialog
                    open={showSamplingConfigDialog}
                    onClose={() => setShowSamplingConfigDialog(false)}
                    samplingReportUUID={samplingReportUUID}
                />
            )}
        </div>
    );
};

export default MultiStepFormReportTable;
