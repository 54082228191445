import React from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { createReferencePoint, saveReferencePointDetail } from "../../../api/referencePointsAPI";
import { BasicOutfallFields } from "./BasicOutfallFields";
import HomeForm from "../../Common/Home/HomeForm";
import { detailsReportMapping } from "../../../utils/reporting";

const AddOutfall = ({ history }) => {
    const { id: propertyId, path: propertyPath } = getURLParentObject("industrialUsers") || {};

    const onSubmit = (basic, details) => {
        let outfallUUID = null;

        // Remove latitude and longitude from the basic object
        const { latitude, longitude, ...outfallData } = basic;

        return createReferencePoint({
            ...outfallData,
            property_uuid: propertyId,
            geom: {
                lat: Number(latitude) || 0,
                lon: Number(longitude) || 0,
            },
        })
            .then((result) => {
                outfallUUID = result.reference_point_uuid;
                return Promise.all(
                    Object.entries(details).map((detail) =>
                        saveReferencePointDetail(result.reference_point_uuid, {
                            form_field_uuid: detail[0],
                            value: typeof detail[1] === "undefined" ? undefined : `${detail[1]}`,
                        })
                    )
                );
            })
            .then(() => history.push(propertyPath));
    };

    return (
        <HomeForm
            title="Add Outfall"
            basicFields={BasicOutfallFields}
            detailsFormName={detailsReportMapping.reference_point}
            onSubmit={onSubmit}
            onCancel={() => history.goBack()}
        />
    );
};

export default withRouter(AddOutfall);
