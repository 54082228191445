import axios from 'axios';
import { getOwnUser } from './usersAPI';
import { history } from '../routers/AppRouter';
import { parseQueryStringParams } from '../utils/url';

axios.defaults.withCredentials = true;
const urlStatus = '/api/status';

export const login = (values) => {
	const urlLogin = '/api/v1/auth/login';
	const payload = JSON.stringify(values);

	if (SwiftComply?.settings?.disable_login === true) {
		// unlike in the axios call, this will throw a "normal" exception so need to catch it with try, not as a promise
		throw "Login is temporarily disabled due to system maintenance.";
	}

	return axios.get(urlStatus)
		.then(() => {
			return axios.post(urlLogin, payload)
				.then(() => {
					return getOwnUser()
						.then(user => {
							let path = '/dashboard';
							if (user.user_type === 'service provider') {
								path = '/testReports';
							} else if (user.modules?.length === 1 && user.modules[0] === 'pretreatment') {
								path = '/pt/industrialUsers';
							}
							let qs = parseQueryStringParams(window.location.search);
							if (qs.whereTo) {
								path = qs.whereTo;
							}
							// redirect them here so we go through the app rendering, user lookup, etc
							window.location.href = path;
						});
				})
				.catch(() => {
					throw 'Invalid Email and/or Password';
				});
		});
};

export const logout = () => {
	const url = '/api/v1/auth/logout';

	axios.post(url, {})
		.then(() => {
			history.push({
				pathname: '/',
			});
		})
		.catch(error => {
			throw error;
		});
};

export const requestPasswordReset = (email) => {
	const url = '/api/v1/auth/password-reset';

	if (SwiftComply?.settings?.disable_login === true) {
		// unlike in the axios call, this will throw a "normal" exception so need to catch it with try, not as a promise
		throw "Password resets are temporarily disabled due to system maintenance.";
	}

	return axios.get(urlStatus).then(() => {
		return axios.post(url, { email })
			.catch(error => {
				throw error;
			});
	});
}

export const resetPassword = (resetRequestUUID, password) => {
	const url = `/api/v1/auth/password-reset/${resetRequestUUID}`;

	if (SwiftComply?.settings?.disable_login === true) {
		// unlike in the axios call, this will throw a "normal" exception so need to catch it with try, not as a promise
		throw "Password resets are temporarily disabled due to system maintenance.";
	}

	return axios.get(urlStatus).then(() => {
		return axios.put(url, { new_password: password })
			.catch(error => {
				throw error;
			});
	});
}

export const getSAMLOptions = () => {
	const url = '/api/v1/auth/saml-idp-options';

	return axios.get(url).then((response) => {
		return response.data;
	});
}