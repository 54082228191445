import React, { useEffect, useState } from "react";
import { getURLParentObject } from "../../../utils/url";
import { getGeneralControl } from "../../../api/permitsAPI";
import { BasicGeneralControlFields } from "./BasicGeneralControlFields";
import EditGeneralControl from "./EditGeneralControl";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";

const ViewGeneralControl = () => {
    const { id: generalControlId } = getURLParentObject("generalControls") || {};
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [basicFields, setBasicFields] = useState({});
    const [editMode, setEditMode] = useState(false);

    const refreshGeneralControl = () => {
        setLoading(true);
        setError(null);

        getGeneralControl(generalControlId)
            .then((data) => {
                setBasicFields(
                    BasicGeneralControlFields.reduce((prev, curr) => ({ ...prev, [curr.id]: data[curr.id] }), {})
                );
            })
            .catch((err) => {
                console.error("Error fetching general control:", err);
                setError(err);
            })
            .finally(() => setLoading(false));
    };

    const onEditComplete = () => {
        setEditMode(false);
        refreshGeneralControl();
    };

    useEffect(() => {
        refreshGeneralControl();
    }, [generalControlId]);

    if (editMode && !loading && !error) {
        return (
            <EditGeneralControl
                preloadedBasicState={basicFields}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    return (
        <Page loading={loading} error={error}>
            <Card title={basicFields.general_control_name || "View General Control"} onEdit={() => setEditMode(true)}>
                <SummaryGrid fields={BasicGeneralControlFields} formState={basicFields} />
            </Card>
        </Page>
    );
};

export default ViewGeneralControl;
