/**
 * Converts form state to permit data structure required by the API.
 *
 * @param {Object} formState - The current state of the form
 * @param {Object} templateData - The template data for the permit
 * @param {Object} tableSelections - The current selections in the table
 * @returns {Object} The converted permit data ready for API submission
 */
export const convertFormStateToPermitData = (formState, templateData, tableSelections) => {
    const convertedData = {
        legal_control_type: formState.legal_control_type,
        template_uuid: formState.template_uuid,
        template_data: {
            reports: templateData.reports.map((report) => {
                const isTableTypePivot = report.table_type === "pivot-table";
                const allRowsSelected = formState[report.report_uuid]?.[report.key]?.rows?.every(
                    (_, index) => tableSelections[report.key]?.[index],
                );

                let inputs = null;
                if (!allRowsSelected) {
                    if (isTableTypePivot) {
                        inputs = JSON.stringify(
                            formState[report.report_uuid]?.[report.key]?.rows
                                ?.filter((_, index) => !formState[report.key]?.selectedRows?.includes(index))
                                .map((row) => ({
                                    ...report.input_baseline,
                                    "Property UUID": formState.industrial_user,
                                    "Sampling Report UUID":
                                        row.values[
                                            formState[report.report_uuid]?.[report.key]?.outputs?.findIndex(
                                                (output) => output.name === "Sampling Report UUID",
                                            )
                                        ],
                                })),
                        );
                    } else {
                        inputs = JSON.stringify(
                            report.required_input_keys.map((key) => ({
                                [key]: formState.industrial_user,
                                ...report.input_baseline,
                            })),
                        );
                    }
                }

                const activeRows = JSON.stringify(
                    formState[report.report_uuid]?.[report.key]?.rows
                        ?.filter((_, index) => !tableSelections[report.key]?.[index])
                        .map((row) =>
                            report.row_key_label.map((key) => {
                                const keyIndex = formState[report.report_uuid]?.[report.key]?.outputs?.findIndex(
                                    (output) => output.name === key,
                                );
                                return row.values[keyIndex];
                            }),
                        ),
                );

                return {
                    report_uuid: report.report_uuid,
                    label: report.label,
                    template_string: report.template_string,
                    key: report.key,
                    active_rows: activeRows,
                    inputs: inputs,
                    visible_columns: report.visible_columns || [],
                };
            }),
            data_entry: templateData.data_entry.map((entry) => ({
                name: entry.name,
                label: entry.label,
                template_string: entry.template_string,
                type: entry.type,
                value: formState[entry.template_string] || "",
            })),
            document_fields: templateData.document_fields.map((field) => ({
                name: field.name,
                label: field.label,
                template_string: field.template_string,
                type: field.type,
                value: formState[field.template_string] || "",
            })),
            system_fields: Object.fromEntries(
                Object.entries(templateData.system_fields).map(([key, systemField]) => [
                    key,
                    {
                        template_fields: systemField.template_fields.map((field) => ({
                            name: field.name,
                            label: field.label,
                            template_string: field.template_string,
                            type: field.type,
                            value: formState[field.template_string] || "",
                        })),
                    },
                ]),
            ),
            system_uuids: {
                property_uuid: formState.industrial_user,
            },
            form_data: JSON.stringify({
                ...formState,
                tableSelections: tableSelections,
            }),
        },
        status: "draft",
    };

    return convertedData;
};

/**
 * Constructs initial table selections based on template data and form state.
 *
 * @param {Object} templateData - The template data for the permit
 * @param {Object} initialFormState - The initial state of the form
 * @returns {Object} An object representing the initial table selections
 */
export const constructTableSelections = (templateData, initialFormState) => {
    const tableSelections = {};

    templateData.reports.forEach((report) => {
        const reportState = initialFormState[report.report_uuid]?.[report.key];
        const reportRows = reportState?.rows || [];

        if (report.table_type === "pivot-table") {
            let inputs = [];
            try {
                inputs = JSON.parse(report.inputs || "[]");
            } catch (error) {
                console.warn(`Failed to parse inputs for report ${report.report_uuid}:`, error);
            }

            const samplingReportUUIDs = inputs.map((input) => input["Sampling Report UUID"]);
            const samplingReportUUIDIndex = reportState?.outputs?.findIndex(
                (output) => output.name === "Sampling Report UUID",
            );

            tableSelections[report.key] = reportRows.map((row) => {
                const rowSamplingReportUUID = row.values[samplingReportUUIDIndex];
                return samplingReportUUIDs.includes(rowSamplingReportUUID) ? null : true;
            });
        } else if (report.active_rows && report.row_key_label) {
            const activeRows = JSON.parse(report.active_rows);
            const rowKeyLabels = report.row_key_label;

            tableSelections[report.key] = reportRows.map((row) => {
                const rowUUIDs = rowKeyLabels.map((label) => {
                    const labelIndex = reportState?.outputs?.findIndex((output) => output.name === label);
                    return row.values[labelIndex];
                });

                const isActiveRow = activeRows.some((activeRow) =>
                    rowUUIDs.every((uuid, index) => activeRow[index] === uuid),
                );

                return isActiveRow ? null : true;
            });
        }
    });

    return tableSelections;
};

/**
 * Handles and displays errors with a default message.
 *
 * @param {Error} error - The error object to handle
 * @param {string} defaultMessage - The default message to display if no specific error message is available
 */
export const handleError = (error, defaultMessage) => {
    if (error) {
        const message = defaultMessage + (error.response?.data?.Error || error.message || "An error occurred");
        const capitalizedMessage = message.charAt(0).toUpperCase() + message.slice(1);
        setGenericError({
            message: capitalizedMessage,
            severity: "error",
            duration: 5000,
        });
    }
};

/**
 * Converts document fields from camelCase to snake_case and extracts their values.
 *
 * @param {Object} convertedFormState - The converted form state containing template data
 * @returns {Object} An object with snake_case keys and corresponding field values
 */
export const convertDocumentFieldsToSnakeCase = (convertedFormState) => {
    return convertedFormState.template_data.document_fields.reduce((acc, td) => {
        const snake = td.name
            .split(/(?=[A-Z])/)
            .map((s) => s.toLowerCase())
            .join("_");
        acc[snake] = td.value || null;
        return acc;
    }, {});
};

/**
 * Maps field types to corresponding input types for form rendering.
 *
 * This helper function takes a field type and returns an object with the appropriate
 * input type and additional properties needed for rendering the form field correctly.
 *
 * @param {string} type - The type of the field to be mapped
 * @returns {Object} An object containing the inputType and additional properties if needed
 */
export const mapInputType = (type) => {
    switch (type) {
        case "string":
            return { inputType: "input" };
        case "textarea":
            return { inputType: "textarea" };
        case "numeric":
            return { inputType: "input", dataType: "numeric" };
        case "checkbox":
            return { inputType: "checkbox" };
        case "date":
            return { inputType: "date" };
        default:
            return { inputType: "input" };
    }
};
