import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router";
import { getURLModule } from "../../../utils/url";
import MultiStepForm from "../../Common/Forms/MultiStepForm";
import useIndustrialUsers from "./useIndustrialUsers";
import usePermitTemplates from "./usePermitTemplates";
import Snackbar from "../../Common/Snackbar";
import Button from "../../Common/Button";
import styles from "../../../styles/common/multiStepFormReportTable.module.css";
import { AddPermitSteps } from "./AddPermitSteps";
import {
    createTemplateDocument,
    updateTemplateDocument,
    createLegalControl,
    updateLegalControl,
    getTemplateDocument,
    deleteTemplateDocument,
    deleteLegalControlPending,
    getPermit,
} from "../../../api/permitsAPI";
import { getReport } from "../../../api/reportingAPI";
import {
    convertFormStateToPermitData,
    constructTableSelections,
    handleError,
    convertDocumentFieldsToSnakeCase,
    mapInputType,
} from "./PermitUtils";

const AddPermit = ({ history, location }) => {
    const { slug } = getURLModule();
    const [loading, setLoading] = useState(false);
    const [templateDataLoading, setTemplateDataLoading] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [templateData, setTemplateData] = useState(null);
    const [formState, setFormState] = useState({});
    const [tableSelections, setTableSelections] = useState({});
    const [fetchedReports, setFetchedReports] = useState({});
    const [permitData, setPermitData] = useState(null);
    const [templateDocumentUUID, setTemplateDocumentUUID] = useState(null);
    const [legalControlUUID, setLegalControlUUID] = useState(null);
    const [showSamplingConfigDialog, setShowSamplingConfigDialog] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [showSaveDraftSuccess, setShowSaveDraftSuccess] = useState(false);
    const [showSaveDraftError, setShowSaveDraftError] = useState(false);
    const [isDraftSaveEnabled, setIsDraftSaveEnabled] = useState(false);

    const { industrialUsers, error: industrialUsersError, loading: industrialUsersLoading } = useIndustrialUsers();
    const { permitTemplates, error: permitTemplatesError, loading: permitTemplatesLoading } = usePermitTemplates();

    useEffect(() => {
        handleError(industrialUsersError, "Error fetching industrial users: ");
    }, [industrialUsersError]);

    useEffect(() => {
        handleError(permitTemplatesError, "Error fetching permit templates: ");
    }, [permitTemplatesError]);

    useEffect(() => {
        const { templateDocumentUUID, legalControlUUID, status } = location.state || {};

        if (templateDocumentUUID) {
            setTemplateDocumentUUID(templateDocumentUUID);
        }

        if (legalControlUUID) {
            setLegalControlUUID(legalControlUUID);
            // Fetch permit data when entering from a pending state
            getPermit(legalControlUUID)
                .then(setPermitData)
                .catch((error) => console.error("Error fetching permit:", error));
        }

        if (templateDocumentUUID) {
            getTemplateDocument(templateDocumentUUID)
                .then((response) => {
                    const { template_data, template_document_uuid } = response;

                    setTemplateData(template_data);
                    setTemplateDocumentUUID(template_document_uuid);

                    let initialFormState;
                    if (template_data.form_data) {
                        initialFormState = JSON.parse(template_data.form_data);
                        // Initialize table selections
                        setTableSelections(constructTableSelections(template_data, initialFormState));
                    } else {
                        // Handle the case when form_data is not available
                        initialFormState = {
                            industrial_user: template_data.system_uuids.property_uuid,
                            template_uuid: response.template_uuid,
                            legal_control_type: response.legal_control_type,
                        };
                    }
                    setFormState(initialFormState);

                    // Set current step based on status
                    if (status === "pending" || status === "draft") {
                        const stepTitle = status === "pending" ? "Sign Document" : "Fill Form";
                        const stepIndex = AddPermitSteps.findIndex((step) => step.title === stepTitle);
                        if (stepIndex !== -1) setCurrentStep(stepIndex);
                    }

                    // Fetch report data for each report in template_data.reports
                    template_data.reports.forEach((report) => {
                        memoizedFetchAndSetReportData(report.report_uuid, initialFormState.industrial_user, report.key);
                    });
                })
                .catch((error) => console.error("Error fetching template document:", error));
        }
    }, [location.state?.templateDocumentUUID, location.state?.legalControlUUID, location.state?.status]);

    useEffect(() => {
        //save draft button is disabled while there are no changes to the form
        setIsDraftSaveEnabled(false);
    }, [templateData]);

    const getSteps = () => {
        const steps = AddPermitSteps.map((step) => ({ ...step }));

        // Update fields in the first step
        const updateField = (fieldId, options, value) => {
            const field = steps[0].fields.find((f) => f.id === fieldId);
            if (field) {
                field.options = options;
                field.value = value;
            }
        };

        updateField("industrial_user", industrialUsers, formState?.industrial_user);
        updateField("template_uuid", permitTemplates, formState?.template_uuid);

        // Populate the second step with fields and tables based on the template data
        if (templateData) {
            const { data_entry, document_fields, system_fields, reports } = templateData;

            // Map template data to form fields
            const mapFields = (fields, disabled = false) =>
                fields.map((field) => ({
                    id: field.template_string,
                    label: field.label,
                    ...mapInputType(field.type),
                    required: field.required,
                    value: formState[field.template_string],
                    ...(disabled && { disabled: true }),
                }));

            steps[1].fields = [
                ...steps[1].fields,
                ...mapFields(data_entry),
                ...mapFields(document_fields),
                ...Object.values(system_fields).flatMap((sf) => mapFields(sf.template_fields, true)),
            ];

            // Map report UUIDs to table keys
            steps[1].tables = reports.map((report) => ({
                reportUUID: report.report_uuid,
                key: report.key,
                visibleColumns: report.visible_columns,
            }));
        }

        return steps;
    };

    const handleFormStateChange = (newState) => {
        setFormState((prevState) => {
            const updatedState = { ...prevState, ...newState };
            return updatedState;
        });

        if (newState.tableSelections) {
            setTableSelections((prevSelections) => ({
                ...prevSelections,
                ...newState.tableSelections,
            }));
        }

        // Clear validation errors when the user selects values for required fields
        if (newState.industrial_user || newState.template_uuid) {
            setValidationErrors((prevErrors) =>
                prevErrors.filter((error) => error.id !== "industrial_user" && error.id !== "template_uuid")
            );
        }

        setIsDraftSaveEnabled(true);
    };

    const handleNextStep = (formState) => {
        // Early return if required fields are not filled
        if (!formState.industrial_user || !formState.template_uuid) return;

        setTemplateDataLoading(true);

        // Function to check if an existing template document matches the selected template or industrial user
        const checkExistingTemplate = () => {
            if (templateDocumentUUID) {
                return getTemplateDocument(templateDocumentUUID).then((data) => {
                    const isSameTemplate = data.template_uuid === formState.template_uuid;
                    const isSameIndustrialUser =
                        data.template_data.system_uuids.property_uuid === formState.industrial_user;

                    if (isSameTemplate && isSameIndustrialUser) {
                        // If both template and industrial user are the same, move to next step without creating a new template document
                        setCurrentStep(1);
                        return Promise.reject("Same template and industrial user");
                    }
                    // If either template or industrial user has changed, we'll create a new template document
                    return Promise.resolve();
                });
            }
            return Promise.resolve();
        };

        // Function to create a new template document
        const createNewTemplateDocument = () => {
            return createTemplateDocument({
                legal_control_type: "permit",
                template_uuid: formState.template_uuid,
                template_data: {
                    system_uuids: {
                        property_uuid: formState.industrial_user,
                    },
                },
            });
        };

        // Function to process the template data after creation
        const processTemplateData = (data) => {
            setTemplateData(data.template_data);
            setTemplateDocumentUUID(data.template_document_uuid);

            const initialFormState = {
                ...formState,
                ...createInitialFormState(data.template_data),
                legal_control_type: "permit",
            };

            setFormState(initialFormState);
            fetchReportData(data.template_data.reports, initialFormState.industrial_user);
        };

        // Chain of operations: check existing template, create new if needed, process data
        checkExistingTemplate()
            .then(createNewTemplateDocument)
            .then(processTemplateData)
            .catch((error) => {
                // Only handle errors that are not from the 'Same template' case
                if (error !== "Same template") {
                    handleError(error, "Error fetching template data: ");
                }
            })
            .finally(() => {
                setTemplateDataLoading(false);
                setCurrentStep(1);
            });
    };

    // Helper function to create initial form state from template data
    const createInitialFormState = (templateData) => {
        return ["document_fields", "data_entry"].reduce((acc, fieldType) => {
            templateData[fieldType].forEach((field) => {
                acc[field.template_string] = field.value || "";
            });
            return acc;
        }, {});
    };

    // Helper function to fetch report data for each report in the template
    const fetchReportData = (reports, industrialUser) => {
        reports.forEach((report) => {
            memoizedFetchAndSetReportData(report.report_uuid, industrialUser, report.key);
        });
    };

    const memoizedFetchAndSetReportData = useMemo(() => {
        return (reportUUID, industrialUser, tableKey) => {
            if (fetchedReports[reportUUID]?.[tableKey]) return;

            const reportObject = templateData?.reports?.find(
                (report) => report.report_uuid === reportUUID && report.key === tableKey
            );

            if (reportObject) {
                const inputs = {};

                if (reportObject.required_input_keys) {
                    reportObject.required_input_keys.forEach((key) => {
                        if (key === "Property UUID") {
                            inputs[key] = formState.industrial_user;
                        } else {
                            inputs[key] = industrialUser;
                        }
                    });
                }

                if (reportObject.input_baseline) {
                    Object.entries(reportObject.input_baseline).forEach(([key, value]) => {
                        inputs[key] = value;
                    });
                }

                getReport(reportUUID, {
                    count: 200,
                    inputs: inputs,
                })
                    .then((data) => {
                        setFormState((prevState) => ({
                            ...prevState,
                            [reportUUID]: { ...prevState?.[reportUUID], [reportObject.key]: data.data },
                        }));
                        setFetchedReports((prevFetchedReports) => ({
                            ...prevFetchedReports,
                            [reportUUID]: { ...prevFetchedReports?.[reportUUID], [reportObject.key]: true },
                        }));
                    })
                    .catch((error) => {
                        console.error("Error fetching report data:", error);
                    });
            }
        };
    }, [fetchedReports, templateData]);

    const onSubmit = (formState) => {
        const convertedFormState = convertFormStateToPermitData(formState, templateData, tableSelections);

        if (permitData) {
            // Update the permit with the signature and status
            const updatedPermitData = {
                property_uuid: convertedFormState.template_data.system_uuids.property_uuid,
                legal_control_uuid: permitData.legal_control_uuid,
                status: "completed",
                template_document_uuid: templateDocumentUUID,
                ...convertDocumentFieldsToSnakeCase(convertedFormState),
                signature: formState.signature_name,
            };

            updateLegalControl(updatedPermitData)
                .then((updatedPermit) => {
                    history.push(`/${slug}/permits`);
                })
                .catch((error) => {
                    console.error("Error updating permit:", error);
                });
        } else {
            console.error("Permit data not available");
        }
    };

    const columnConfig = {
        "Analyte(s)": {
            render: ({ value, isSelected, samplingReportUUID, handleAnalyteClick }) => (
                <div
                    className={`${styles.analyteLink} ${isSelected ? styles.disabled : ""}`}
                    onClick={() => handleAnalyteClick(samplingReportUUID)}
                >
                    {value}
                </div>
            ),
        },
    };

    const onCancel = () => {
        history.goBack();
    };

    const onStepChange = (stepIndex, isGoingBack) => {
        // Convert form state and prepare common fields
        const convertedFormState = convertFormStateToPermitData(formState, templateData, tableSelections);
        const commonFields = {
            property_uuid: formState.industrial_user,
            template_document_uuid: templateDocumentUUID,
            ...convertDocumentFieldsToSnakeCase(convertedFormState),
        };

        if (isGoingBack && stepIndex === 2) {
            // Handle going back to step 2
            if (legalControlUUID) {
                deleteLegalControlPending(legalControlUUID)
                    .then(() => {
                        // Update template document after deleting pending legal control
                        return updateTemplateDocument(
                            templateDocumentUUID,
                            { ...convertedFormState, ...commonFields, status: "draft" },
                            formState
                        );
                    })
                    .then(() => {
                        // Create a new legal control with draft status
                        return createLegalControl({ ...commonFields, status: "draft" });
                    })
                    .then((response) => {
                        setPdfData(response.pdf);
                    })
                    .catch((error) => {
                        console.error("Error handling step back to 3:", error);
                    });
            } else {
                console.warn("No legalControlUUID found for deletion.");
            }
        } else if (stepIndex === 2) {
            // Handle moving forward to step 2
            updateTemplateDocument(
                templateDocumentUUID,
                { ...convertedFormState, ...commonFields, status: "draft" },
                formState
            )
                .then(() => {
                    // Create a new legal control with draft status
                    return createLegalControl({ ...commonFields, status: "draft" });
                })
                .then((response) => {
                    setPdfData(response.pdf);
                    setPermitData(response);
                })
                .catch((error) => {
                    console.error("Error handling step forward to 3:", error);
                });
        } else if (stepIndex === 3) {
            // Handle moving forward to step 3
            createLegalControl({ ...commonFields, status: "pending" })
                .then((updatedPermit) => {
                    setPermitData(updatedPermit);
                    setLegalControlUUID(updatedPermit.legal_control_uuid);
                    // Update template document after creating legal control
                    return updateTemplateDocument(
                        templateDocumentUUID,
                        { ...convertedFormState, ...commonFields, status: "pending" },
                        formState
                    );
                })
                .catch((error) => {
                    console.error("Error handling step forward to 4:", error);
                });
        }
    };

    const renderExtraButtons = () => {
        if (currentStep === 1) {
            return (
                <Button
                    onClick={() => {
                        const convertedFormState = convertFormStateToPermitData(
                            formState,
                            templateData,
                            tableSelections
                        );

                        updateTemplateDocument(templateDocumentUUID, convertedFormState, formState)
                            .then(() => {
                                setShowSaveDraftSuccess(true);
                                setShowSaveDraftError(false);
                                setIsDraftSaveEnabled(false);
                            })
                            .catch((error) => {
                                console.error("Error saving draft:", error);
                                setShowSaveDraftError(true);
                                setShowSaveDraftSuccess(false);
                            });
                    }}
                    disabled={!isDraftSaveEnabled}
                >
                    Save Draft
                </Button>
            );
        }
        return null;
    };

    const onDelete = () => {
        if (currentStep === 3 && legalControlUUID) {
            // Use deleteLegalControlPending endpoint on Step 4, pending state delete requires legal control uuid
            deleteLegalControlPending(legalControlUUID)
                .then(() => {
                    return deleteTemplateDocument(templateDocumentUUID);
                })
                .then(() => {
                    history.push(`/${slug}/permits`);
                })
                .catch((error) => {
                    console.error("Error deleting legal control or template document:", error);
                });
        } else if (currentStep === 1 || currentStep === 2) {
            // Use deleteTemplateDocument endpoint on Steps 2 and 3
            deleteTemplateDocument(templateDocumentUUID)
                .then(() => {
                    history.push(`/${slug}/permits`);
                })
                .catch((error) => {
                    console.error("Error deleting template document:", error);
                });
        }
    };

    const onValidationError = (errors) => {
        setValidationErrors(errors);
    };

    const isLoading = industrialUsersLoading || permitTemplatesLoading || templateDataLoading;

    return (
        <>
            <MultiStepForm
                title="Add Permit"
                steps={getSteps()}
                initialState={formState}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onStepChange={onStepChange}
                onFormStateChange={handleFormStateChange}
                tableSelections={tableSelections}
                setTableSelections={setTableSelections}
                columnConfig={columnConfig}
                showSamplingConfigDialog={showSamplingConfigDialog}
                setShowSamplingConfigDialog={setShowSamplingConfigDialog}
                pdfData={pdfData}
                validationErrors={validationErrors}
                onValidationError={onValidationError}
                templateDataLoading={templateDataLoading}
                templateData={templateData}
                onNextStep={handleNextStep}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                fetchAndSetReportData={memoizedFetchAndSetReportData}
                renderExtraButtons={renderExtraButtons}
                deleteButtonStep={1}
                onDelete={onDelete}
                showDelete={currentStep === 1 || currentStep === 2 || currentStep === 3}
                loading={isLoading}
            />
            {showSaveDraftSuccess && (
                <Snackbar
                    message="Your changes have been saved as a draft."
                    onClose={() => setShowSaveDraftSuccess(false)}
                    severity="info"
                />
            )}
            {showSaveDraftError && (
                <Snackbar message="Unable to save as draft." onClose={() => setShowSaveDraftError(false)} />
            )}
        </>
    );
};

export default withRouter(AddPermit);
