/** @type {formField[]} */
export const BasicSamplingReportFields = [
    {
        id: "assigned_to",
        label: "Assigned To",
        inputType: "select",
        options: [
            { label: "Industry", value: "property" },
            { label: "Authority", value: "organization" },
        ],
        required: true,
    },
    {
        id: "lab_report_required",
        label: "Lab Report",
        inputType: "select",
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        toggle: {
            label: "Compliance",
            id: "compliance_determinants",
            behavior: "add_to_array",
            hideWhen: (formState) => formState.assigned_to === "organization",
        },
        required: true,
    },
    {
        id: "custody_chain_required",
        label: "Chain of Custody",
        inputType: "select",
        dataType: "boolean",
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        toggle: {
            label: "Compliance",
            id: "compliance_determinants",
            behavior: "add_to_array",
            hideWhen: (formState) => formState.assigned_to === "organization",
        },
        required: true,
    },
    { id: "additional_requirements", label: "Additional Requirements (optional)", inputType: "textarea" },
    {
        id: "general_control_type",
        label: "Connect to Legal Control",
        inputType: "radio",
        options: [
            { label: "General Control", value: "general_control" },
            { label: "Specific Control", value: "specific_control" },
        ],
        hideWhen: (formState) => formState.assigned_to === "organization",
    },
    {
        id: "general_control",
        label: "Select General Control",
        inputType: "select",
        options: [], // This will be populated with fetched data
        hideWhen: (formState) => formState.assigned_to === "organization" || formState.general_control_type === "specific_control",
    },
    {
        id: "report_schedule",
        label: "Report Scheduling",
        inputType: "radio",
        options: [
            { label: "Ad Hoc", value: "ad_hoc" },
            { label: "Recurring", value: "recurring" },
        ],
        hideWhen: (formState) => formState.assigned_to === "property" && formState.general_control_type === "specific_control",
    },
    {
        id: "report_due",
        label: "Ad Hoc Due Date",
        timeLabel: "Ad Hoc Due Time",
        inputType: "date_time",
        required: true,
        hideWhen: (formState) => formState.report_schedule !== "ad_hoc" || formState.assigned_to === "property" && formState.general_control_type === "specific_control",
    },
    {
        id: "recurrence",
        label: "Recurrence",
        inputType: "recurrence",
        hideWhen: (formState) => formState.report_schedule !== "recurring" || formState.assigned_to === "property" && formState.general_control_type === "specific_control",
        required: true,
    },
];
