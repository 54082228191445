import { getURLBaseSection } from "./url";

/**
 * Get the corresponding "permission name" for the back-end based on the current path
 */
export const urlToPermission = () => {
    const sectionMapping = {
        assemblies: "Equipment",
        communications: "Communications",
        config: "default",
        contacts: "Contacts",
        dashboard: "Dashboards",
        datatables: "Reporting",
        industrialUsers: "Properties",
        locations: "Properties",
        notifications: "Notifications",
        organization: "Settings",
        outfalls: "ReferencePoints",
        payments: "Payments",
        permits: "LegalControls", //FIXME in case legalControls service is not used for permits
        profile: "default", // anyone logged in should get access to this
        reporting: "Reporting",
        samplingReports: "ReferencePoints",
        serviceProviders: "ServiceProviders",
        settings: "Settings",
        stateReports: "StateReports",
        surveys: "ComplianceReports",
        templates: "LegalControls",
        testReports: "ComplianceReports",
        users: "Users",
    };

    return sectionMapping[getURLBaseSection()];
};
