import React, { useEffect, useState } from "react";
import { getURLParentObject } from "../../../utils/url";
import { getLimit } from "../../../api/referencePointsAPI";
import { BasicLimitFields } from "./LimitFields";
import EditLimit from "./EditLimit";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import useGeneralControls from "../GeneralControls/useGeneralControls";

const ViewLimit = () => {
    const { id: outfallId } = getURLParentObject("outfalls") || {};
    const { id: limitId } = getURLParentObject("limits") || {};
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [basicFields, setBasicFields] = useState({});
    const [editMode, setEditMode] = useState(false);
    const { generalControls, loading: generalControlsLoading } = useGeneralControls();

    const refreshLimit = () => {
        setLoading(true);
        setError(null);

        getLimit(outfallId, limitId)
            .then((data) =>
                setBasicFields({
                    ...BasicLimitFields.reduce((prev, curr) => ({ ...prev, [curr.id]: data[curr.id] }), {}),
                    general_control_uuid: data.general_control_uuid,
                })
            )
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const onEditComplete = () => {
        setEditMode(false);
        refreshLimit();
    };

    useEffect(() => {
        refreshLimit();
    }, []);

    if (editMode && !loading && !error) {
        return (
            <EditLimit
                preloadedBasicState={basicFields}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    const fields = BasicLimitFields.filter((f) => f.id !== "general_control_label").map((f) =>
        f.id === "general_control" ? { ...f, label: "General Control" } : f
    );

    const values = {
        ...basicFields,
        general_control:
            generalControls.find((control) => control.value === basicFields.general_control_uuid)?.label ||
            "Not connected",
    };

    return (
        <Page loading={loading || generalControlsLoading} error={error}>
            <Card title={basicFields.analyte || "View Limit"} onEdit={() => setEditMode(true)}>
                <SummaryGrid fields={fields} formState={values} />
            </Card>
        </Page>
    );
};

export default ViewLimit;
