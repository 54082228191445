import React, { useState } from "react";
import { getRecurrences } from "../../../../api/analytesAPI";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    TextField,
} from "@material-ui/core";
import { dateToAPIString, formatDate, recurrenceToText } from "../../../../utils/forms";
import { ExpandMoreRounded } from "@material-ui/icons";
import accordionStyles from "../../../../styles/common/matieralUI/accordion.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";
import Form from "../Form";
import Button from "../../Button";
import LoadingSpinner from "../../LoadingSpinner";

const RecurrenceField = ({
    label,
    value = {
        day_or_date: "date",
        interval: "1",
        day_of_week: "MO",
        nth: "1",
        start: dateToAPIString(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
        until: dateToAPIString(new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)),
    },
    setValue,
    error,
    showDates,
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [previewValue, setPreviewValue] = useState(value);
    const [previewDates, setPreviewDates] = useState([]);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [previewError, setPreviewError] = useState(null);
    const [dates, setDates] = useState([]);

    const updatePreview = (state) => {
        const currentState = state ?? previewValue;

        setPreviewError(null);

        if (!currentState.frequency) {
            setPreviewDates([]);
            return;
        }

        setPreviewLoading(true);
        getRecurrences(currentState)
            .then((data) => setPreviewDates(data.date_times))
            .catch(setPreviewError)
            .finally(() => setPreviewLoading(false));
    };

    const onFormStateChange = (state) => {
        updatePreview(state);
        setPreviewValue(state);
    };

    const onApply = () => {
        setShowDialog(false);
        setValue(previewValue);
        setDates(previewDates);
    };

    const onShowDialog = () => {
        updatePreview();
        setShowDialog(true);
    };

    const onCancel = () => {
        setShowDialog(false)
        setPreviewValue(value);
    };

    return (
        <>
            <FormControl error={!!error}>
                <TextField
                    onClick={onShowDialog}
                    InputProps={{
                        readOnly: true,
                    }}
                    label={label}
                    variant="outlined"
                    value={recurrenceToText(value) ?? ""}
                    error={!!error}
                />
                {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
            </FormControl>
            {showDates && !!dates.length && (
                <div>
                    <ul>
                        {dates.map((d) => (
                            <li key={d}>{formatDate(d)}</li>
                        ))}
                    </ul>
                </div>
            )}
            <Dialog open={showDialog} onClose={onCancel}>
                <DialogContent>
                    <Form
                        fields={[
                            {
                                id: "frequency",
                                label: "Frequency",
                                inputType: "select",
                                options: [
                                    { label: "Daily", value: "daily" },
                                    { label: "Weekly", value: "weekly" },
                                    { label: "Monthly", value: "monthly" },
                                    { label: "Annually", value: "annually" },
                                ],
                            },
                            { id: "interval", label: "Interval", dataType: "numeric" },
                            {
                                id: "day_or_date",
                                label: "Day of Month",
                                inputType: "select",
                                options: [
                                    { label: "On the same date every month", value: "date" },
                                    { label: "On the Nth weekday every month", value: "weekday" },
                                    { label: "On the last day of the month", value: "last" },
                                ],
                                hideWhen: (formState) => formState.frequency !== "monthly",
                            },
                            {
                                id: "nth",
                                label: "On the",
                                inputType: "radio",
                                options: [
                                    { label: "First", value: "1" },
                                    { label: "Second", value: "2" },
                                    { label: "Third", value: "3" },
                                    { label: "Fourth", value: "4" },
                                    { label: "Last", value: "-1" },
                                ],
                                hideWhen: (formState) =>
                                    formState.frequency !== "monthly" || formState.day_or_date !== "weekday",
                            },
                            {
                                id: "day_of_week",
                                label: "Weekday",
                                inputType: "radio",
                                options: [
                                    { label: "Monday", value: "MO" },
                                    { label: "Tuesday", value: "TU" },
                                    { label: "Wednesday", value: "WE" },
                                    { label: "Thursday", value: "TH" },
                                    { label: "Friday", value: "FR" },
                                    { label: "Saturday", value: "SA" },
                                    { label: "Sunday", value: "SU" },
                                ],
                                hideWhen: (formState) =>
                                    formState.frequency !== "monthly" || formState.day_or_date !== "weekday",
                            },
                            { id: "start", label: "Start", inputType: "date_time" },
                            { id: "until", label: "Until", inputType: "date_time" },
                        ]}
                        formState={previewValue}
                        onFormStateChange={onFormStateChange}
                        validationErrors={previewError?.Details}
                    />
                    {previewError?.response?.data?.Error ? (
                        <div>
                            <p>Error:</p>
                            <p>{previewError?.response?.data?.Error}</p>
                        </div>
                    ) : (
                        <Accordion classes={{ root: accordionStyles.accordion }} square={false}>
                            <AccordionSummary
                                classes={{
                                    root: accordionStyles.accordionSummary,
                                    expandIcon: accordionStyles.expandIcon,
                                }}
                                expandIcon={<ExpandMoreRounded />}
                            >
                                Preview ({previewDates.length ?? 0} dates):
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: accordionStyles.accordionDetails }}>
                                {previewLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <ul>
                                        {previewDates.map((d) => (
                                            <li key={d}>{formatDate(d)}</li>
                                        ))}
                                    </ul>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={onApply}>
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RecurrenceField;
