import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { createProperty, savePropertyDetail, updatePropertySRFields } from "../../../api/propertiesAPI";
import { stateReportFieldsByState } from "../../../utils/stateReportFields";
import { getURLModule, getURLBaseSection } from "../../../utils/url";
import { getFieldOptions } from "../../../utils/forms";
import { BasicPropertyFields } from "./BasicPropertyFields";
import { checkDuplicates } from "../../../api/duplicatesAPI";
import { detailsReportMapping } from "../../../utils/reporting";
import HomeForm from "../Home/HomeForm";

const AddProperty = ({ title, history }) => {
    const { module, slug } = getURLModule();
    const baseSection = getURLBaseSection();
    const { organization } = useSelector((state) => state.swiftComply);
    const { country_code = "US" } = organization ?? {};

    const onSubmit = (basic, details, sr) => {
        let propertyUUID = null;

        return createProperty({ ...basic, country_code })
            .then((result) => {
                propertyUUID = result.property_uuid;

                return Promise.all(
                    Object.entries(details).map((detail) =>
                        savePropertyDetail(propertyUUID, {
                            form_field_uuid: detail[0],
                            value: typeof detail[1] === "undefined" ? undefined : `${detail[1]}`,
                        })
                    )
                );
            })
            .then(() => {
                if (Object.keys(sr).length) {
                    return updatePropertySRFields(propertyUUID, sr);
                }
            })
            .then(() => history.push(`/${slug}/${baseSection}/${propertyUUID}`));
    };

    const onCheckDuplicates = (basic) =>
        checkDuplicates("properties", basic.name).then((response) =>
            (response.duplicates || []).map((d) => ({
                label: [d.value, d.additional_fields?.address1, d.additional_fields?.city].filter(Boolean).join(", "),
                value: d.uuid,
            }))
        );

    return (
        <HomeForm
            title={title}
            basicFields={BasicPropertyFields}
            detailsFormName={detailsReportMapping.property}
            srFields={stateReportFieldsByState[module]?.properties?.[organization.state_prov]?.map((f) => ({
                id: f.id,
                label: f.label,
                inputType: f.input_type,
                dataType: f.data_type,
                options: getFieldOptions(f.values),
                required: f.required,
            }))}
            onSubmit={onSubmit}
            onCancel={() => history.goBack()}
            onCheckDuplicates={onCheckDuplicates}
            onUseDuplicate={(uuid) => history.push(`/${slug}/${baseSection}/${uuid}`)}
        />
    );
};

export default withRouter(AddProperty);
