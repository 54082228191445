import React from "react";
import { getURLModule, getURLBaseSection } from "../../../utils/url";
import Home from "../Home/Home";
import AdvancedReportTable from "../ReportTable/AdvancedReportTable";

const ContactsHome = () => {
    const { slug } = getURLModule();
    const baseSection = getURLBaseSection();

    return (
        <Home title="Contacts" addNewFormName="addContact" addNewButtonText="New Contact">
            <AdvancedReportTable
                reportUUID="76ab96f4-f941-45be-86f8-157f9484422a"
                defaultColumns={["Name", "Phone", "Email", "Address", "City"]}
                rowLinks={(row) => `/${slug}/${baseSection}/${row["Contact UUID"]}`}
            />
        </Home>
    );
};

export default ContactsHome;
