import queryString from "query-string";
import validator from "validator";

/**
 * Get the current module and slug from the URL path
 * @returns {{module:string, slug:string|null}}
 */
export const getURLModule = () => {
    const pathParts = window.location?.pathname?.split("/").filter(Boolean);
    const modulePathnames = {
        pt: "pretreatment",
        bf: "backflow",
    };

    const slug = modulePathnames[pathParts[0]] ? pathParts[0] : null;
    const module = slug ? modulePathnames[slug] : "backflow";

    return { module, slug };
};

/**
 * Get the base section from the URL path
 * @returns {string|null}
 */
export const getURLBaseSection = () => {
    const pathParts = window.location?.pathname?.split("/").filter(Boolean);
    const modulePathnames = {
        pt: "pretreatment",
        bf: "backflow",
    };

    if (modulePathnames[pathParts[0]]) {
        // If the first part is a module slug, return the second part
        return pathParts[1] || null;
    } else {
        // If not, return the first part
        return pathParts[0] || null;
    }
};

/**
 * Get the parent object from the URL path based on the specified section
 * @param {string} section - The section to search for in the URL path
 * @returns {{section:string, id:string|null, path:string|null}|null}
 */
export const getURLParentObject = (section) => {
    const pathParts = window.location?.pathname?.split("/").filter(Boolean);
    const { isUUID } = validator;

    const isNumericId = (str) => /^\d+$/.test(str);

    for (let i = 0; i < pathParts.length - 1; i++) {
        if (pathParts[i] === section && (isUUID(pathParts[i + 1]) || isNumericId(pathParts[i + 1]))) {
            const id = pathParts[i + 1];
            const path = `/${pathParts.slice(0, i + 2).join("/")}`;
            return { section, id, path };
        }
    }

    return null;
};

/**
 * The window.location.pathname string may or may not have a trailing slash, so this function makes it consistent
 * @returns {string}
 */
export const getPathname = () => window.location.pathname.replace(/\/$/, "");

export const updateURL = (path, params, sendBack) => {
    let newPath = buildURLPath(path, params);

    // according to Mozilla the second param, title, is mostly ignored and passing an empty string should be safe for
    // now and the future
    if (sendBack) {
        return {}, "", newPath;
    } else {
        window.history.pushState({}, "", newPath);
    }
};

export const buildURLPath = (path, params) => {
    let qs = {};
    if (params !== undefined) {
        qs = params;
        for (let key in params) {
            qs[key] = params[key];
        }
    } else {
        qs = queryString.parse(window.location.search);
    }

    let query = queryString.stringify(qs);
    if (query !== "") {
        query = "?" + query;
    }
    return path + query;
};

// yes this is about useless but since we were doing all the query-string stuff here I figured I would just do it like this
export const parseQueryStringParams = (search) => {
    return queryString.parse(search);
};

export const isValidURL = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};
