import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { dateToAPIString, timestampToDate } from "../../../../utils/forms";
import { FormControl, FormHelperText } from "@material-ui/core";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AccessTimeRounded, ClearRounded } from "@material-ui/icons";
import styles from "../../../../styles/common/dateTimePicker.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";
import IconButton from "../../IconButton";
import DatePicker from "./DatePicker";

const DateTimeField = ({ label, timeLabel = "Time", disableFuture, value, setValue, error }) => {
    // Parse the initial date and time from the value, if available
    let initialDate = value ? timestampToDate(value) : null;

    const [date, setDate] = useState(initialDate);
    const [time, setTime] = useState(initialDate);

    const handleDateChange = (newDate) => {
        setDate(newDate);
        updateParentState(newDate, time);
    };

    const handleTimeChange = (newTime) => {
        setTime(newTime);
        updateParentState(date, newTime);
    };

    const updateParentState = (newDate, newTime) => {
        if (newDate && newTime) {
            setValue(
                dateToAPIString(
                    new Date(
                        newDate.getFullYear(),
                        newDate.getMonth(),
                        newDate.getDate(),
                        newTime.getHours(),
                        newTime.getMinutes(),
                        newTime.getSeconds(),
                    )
                )
            );
        } else {
            setValue(undefined);
        }
    };

    return (
        <FormControl error={!!error}>
            <div className={styles.wrapper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        label={label}
                        date={date}
                        handleDateChange={handleDateChange}
                        disableFuture={disableFuture}
                        error={!!error}
                    />
                    <KeyboardTimePicker
                        InputProps={{
                            endAdornment: <IconButton icon={<ClearRounded />} onClick={() => handleTimeChange(null)} />,
                        }}
                        InputAdornmentProps={{ position: "start" }}
                        keyboardIcon={<AccessTimeRounded />}
                        style={{ flexGrow: 1 }}
                        ampm={true}
                        variant="inline"
                        inputVariant="outlined"
                        label={timeLabel}
                        value={time}
                        onChange={handleTimeChange}
                        error={!!error}
                    />
                </MuiPickersUtilsProvider>
            </div>
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default DateTimeField;
