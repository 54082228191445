import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const ModalError = () => {
    return (
        <Dialog open={true} id="modal-error" className="hide">
            <DialogContent>
                <div id="modal-error-message"></div>
                <div id="modal-error-reference"></div>
            </DialogContent>
            <DialogActions>
                <button className="smallButtonSecondary" onClick={() => {
                    document.getElementById("modal-error").classList.add("hide");
                    document.getElementById("modal-error-message").innerText = "";
                    document.getElementById("modal-error-reference").innerText = "";
                }}>close</button>
            </DialogActions>
        </Dialog>
    )
};

export default ModalError;