import React from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { BasicOutfallFields } from "./BasicOutfallFields";
import { detailsReportMapping } from "../../../utils/reporting";
import { deleteReferencePoint, saveReferencePointDetail, updateReferencePoint } from "../../../api/referencePointsAPI";
import HomeForm from "../../Common/Home/HomeForm";

const EditOutfall = ({ preloadedBasicState, preloadedDetailsState, onEditComplete, onEditCancelled, history }) => {
    const { id: outfallId, path: outfallPath } = getURLParentObject("outfalls") || {};

    const onSubmit = (basic, details) => {
        // Remove latitude and longitude from the basic object
        const { latitude, longitude, ...outfallData } = basic;

        return updateReferencePoint(outfallId, {
            ...outfallData,
            type: "Outfall",
            geom: {
                lat: Number(latitude) || 0,
                lon: Number(longitude) || 0,
            },
        })
            .then(() =>
                Promise.all(
                    Object.entries(details).map((detail) =>
                        saveReferencePointDetail(outfallId, {
                            form_field_uuid: detail[0],
                            value: typeof detail[1] === "undefined" ? undefined : `${detail[1]}`,
                        })
                    )
                )
            )
            .then(onEditComplete);
    };

    const onDelete = () =>
        deleteReferencePoint(outfallId).then(() => history.push(outfallPath.split("/").slice(0, -1).join("/")));

    return (
        <HomeForm
            title="Edit Outfall"
            basicFields={BasicOutfallFields}
            detailsFormName={detailsReportMapping.reference_point}
            onSubmit={onSubmit}
            onCancel={onEditCancelled}
            onDelete={onDelete}
            preloadedBasicState={preloadedBasicState}
            preloadedDetailsState={preloadedDetailsState}
        />
    );
};

export default withRouter(EditOutfall);
