import React from 'react';
import users from '../../../images/usersBlue.svg';
import Flyout from '../Flyout';
import { getUsersReport, getUser, inviteUser } from '../../api/usersAPI';
import validator from 'validator';
import { updateURL } from '../../utils/url';
import usersLarge from '../../../images/usersBlue.svg';
import { hasRole } from '../../api/permissionsAPI';

import ReportTable from '../ReportTable/ReportTable';

class UsersDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			flyoutActive: false,
			userInfo: {},
			newUser: false,
			userUUID: null,
			userTableColumns: ['Name', 'Email', 'Job Title', 'Phone'],
			providerTableColumns: ['Name', 'Email', 'Certification Status', 'Job Title', 'Phone'],
			userType: null,
			isOrgAdmin: false
		};

		this.table = React.createRef();

		let urlParts = this.props.location.pathname.split("/");
		if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
			this.state.userUUID = urlParts[2];
		} else if (urlParts.length >= 3 && urlParts[2] == 'newuser') {
			this.state.newUser = true;
		}

		if (this.props.location.comingFrom === 'SP') {
			this.state.userType = "Service Provider Users";
		} else {
			this.state.userType = "Organization Users";
		}
	}

	componentDidMount() {
		if (this.state.userUUID !== null) {
			this.openFlyout({ user_uuid: this.state.userUUID }, false);
		}
		if (this.state.newUser === true) {
			this.openFlyout(null, true)
		}

		hasRole("Org.Admin").then(hasRole => {
			if (hasRole) {
				this.setState(() => ({ isOrgAdmin: true }))
			}
		})
	}

	openFlyout = async (userInfo, newUser) => {
		const overlay = document.getElementById('overlay');

		if (newUser) {
			this.setState({ newUser: true, userInfo: {} });
			updateURL('/users/newuser', {});
		} else {
			userInfo = {
				user_uuid: userInfo.user_uuid
			};
			userInfo = await getUser(userInfo.user_uuid);
			this.setState({ newUser: false, userInfo });
			// update the url in the browser without causing any reloads
			updateURL('/users/' + userInfo.user_uuid, {});
		}
		this.setState(() => ({ flyoutActive: true }), () => {
			overlay.classList.add('overlay__visible');
		});
	}

	closeFlyout = async () => {
		const flyout = document.getElementById('flyout');
		const overlay = document.getElementById('overlay');

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlay__visible');

		setTimeout(() => {
			this.setState(() => ({
				flyoutActive: false,
				userInfo: {},
				userUUID: null
			}));
		}, 200);

		this.table.current.ParentEventHandler('flyout closed', { path: '/users' });
	}

	getReportType = (type) => {
		this.setState(() => ({ userType: type.name }));
	}

	render() {
		const { flyoutActive, userInfo, newUser, userType, isOrgAdmin } = this.state;

		return (
			<div className="componentContainer" id="usersDashboard">
				<div className="componentHeader">
					<div className="flexAlignCenter">
						<img src={users} alt='' />
						<h3>Users</h3>
					</div>
					<button onClick={() => this.openFlyout(null, true)} className="longButtonPrimary">Add User</button>
				</div>
				<ReportTable
					ref={this.table}
					page='users'
					tableType='Users'
					openFlyout={this.openFlyout}
					flyoutActive={flyoutActive}
					defaultReportIdx={this.state.userType === 'Organization Users' ? '0' : '1'}
					sendReportType={this.getReportType}
					reports={[
						{
							reportFunction: (reportingParams) => {
								return getUsersReport("normal", reportingParams);
							},
							reportUUID: "f60dbccd-0d3e-4987-b78e-fbb46a0e2a27",
							name: "Organization Users",
							columns: this.state.userTableColumns
						},
						{
							reportFunction: (reportingParams) => {
								return getUsersReport("service provider", reportingParams);
							},
							reportUUID: "f60dbccd-0d3e-4987-b78e-fbb46a0e2a27",
							name: "Service Provider Users",
							columns: this.state.providerTableColumns
						},
					]}
					removeInputs={["user type"]}
				/>
				<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={usersLarge} alt='' />
				<div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }}>
				</div>
				{flyoutActive && (
					<React.Fragment>
						<Flyout
							uuid={userInfo.user_uuid}
							data={userInfo}
							newAddition={newUser}
							userType={userType}
							flyoutType='users'
							flyoutPriority='primary'
							openFlyout={this.openFlyout}
							closeFlyout={this.closeFlyout}
							activeTab='userDetails'
							isOrgAdmin={isOrgAdmin}
							refresh={() => this.table.current.ParentEventHandler('needs update')}
							isServiceProviderUser={userInfo.user_type === 'service provider'}
							serviceProvider={this.props.location?.serviceProvider}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default UsersDashboard;

<button className="longButtonPrimary" onClick={() => this.userSidebar(null, true)}>Add User</button>