import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getPathname, getURLParentObject } from "../../../utils/url";
import { getSampleReportConfig } from "../../../api/analytesAPI";
import { BasicSamplingReportFields } from "./SamplingReportFields";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import EditSamplingReport from "./EditSamplingReport";
import BasicReportTable from "../../Common/ReportTable/BasicReportTable";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import { VerifiedUserRounded } from "@material-ui/icons";

const ViewSamplingReport = ({ history }) => {
    const { id: samplingReportId } = getURLParentObject("samplingReports") || {};
    const pathname = getPathname();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [reportData, setReportData] = useState({});
    const { loading: generalControlLoading } = useGeneralControls();

    const refreshSamplingReport = () => {
        setLoading(true);
        setError(null);

        getSampleReportConfig(samplingReportId)
            .then(setReportData)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        refreshSamplingReport();
    }, []);

    const onEditComplete = () => {
        setEditMode(false);
        refreshSamplingReport();
    };

    if (editMode && !loading && !error) {
        return (
            <EditSamplingReport
                preloadedBasicState={reportData}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    const notShownOnSummary = ["report_schedule", "general_control_label", "general_control", "general_control_type"];
    const fields = BasicSamplingReportFields.filter((f) => !notShownOnSummary.includes(f.id));

    return (
        <Page loading={loading || generalControlLoading} error={error}>
            <Card title="Sample Report Config" onEdit={() => setEditMode(true)}>
                <SummaryGrid
                    fields={fields}
                    formState={reportData}
                    arrayIcons={{ compliance_determinants: <VerifiedUserRounded /> }}
                />
            </Card>
            <Card
                title="Sampling Configuration"
                onCreate={() =>
                    history.push({
                        pathname: `${pathname}/addSamplingConfig`,
                        state: { reportConfigData: reportData },
                    })
                }
                onCreateLabel="New Sampling Configuration"
            >
                <BasicReportTable
                    reportUUID="6a8542cb-6309-4da1-a06f-55958e4ca036"
                    reportingParams={{ inputs: { "Sample Report Config Id": samplingReportId } }}
                    visibleColumns={["Analytes", "Lab Methods", "Sample Type", "Pacing", "Notes"]}
                    rowLinks={(row) => `${pathname}/samplingConfig/${row["Sampling Config ID"]}`}
                />
            </Card>
        </Page>
    );
};

export default withRouter(ViewSamplingReport);
