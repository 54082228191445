import React from "react";
import { getURLModule, getURLBaseSection } from "../../../utils/url";
import Home from "../../Common/Home/Home";
import AdvancedReportTable from "../../Common/ReportTable/AdvancedReportTable";

const OutfallsHome = () => {
    const { slug } = getURLModule();
    const baseSection = getURLBaseSection();

    return (
        <Home title="Outfalls">
            <AdvancedReportTable
                reportUUID="9c68e84f-22ec-4cd1-9e02-db4268b38d2c"
                defaultColumns={["Outfall Name", "ID", "Subtype", "Size", "Name", "Address"]}
                rowLinks={(row) => `/${slug}/${baseSection}/${row["Reference Point UUID"]}`}
            />
        </Home>
    );
};

export default OutfallsHome;
