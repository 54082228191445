import React, { useState, useEffect } from "react";
import Page from "../Page";
import Card from "../Card";
import Form from "./Form";
import Button from "../Button";
import ProgressBar from "./ProgressBar";
import styles from "../../../styles/common/multiStepForm.module.css";
import MultiStepFormReportTable from "../ReportTable/MultistepFormReportTable";
import Snackbar from "../Snackbar";
import LoadingSpinner from "../../LoadingSpinner";

/**
 * Renders a multi-step form with progress bar and navigation buttons
 *
 * @param {string} title - The title of the form
 * @param {Array} steps - An array of step objects containing form fields
 * @param {Object} [initialState] - The initial state of the form
 * @param {Function} onSubmit - Callback function to handle form submission
 * @param {Function} onCancel - Callback function to handle form cancellation
 * @param {Function} onStepChange - Callback function to handle step changes
 * @param {Function} onFormStateChange - Callback function to update the form state in the parent component
 * @param {Object} tableSelections - Object containing table selection state
 * @param {Function} setTableSelections - Function to update table selection state
 * @param {Object} columnConfig - Object containing column configuration for table rendering
 * @param {boolean} showSamplingConfigDialog - Flag to control the visibility of the sampling config dialog
 * @param {Function} setShowSamplingConfigDialog - Function to update the visibility of the sampling config dialog
 * @param {string} pdfData - Base64-encoded PDF data to be displayed in the preview step
 * @param {Function} onValidationError - Callback function to handle validation errors
 * @param {Array} validationErrors - Array of validation errors
 * @returns {JSX.Element} The rendered component
 */
const MultiStepForm = ({
    title,
    steps,
    initialState,
    onSubmit,
    onCancel,
    onStepChange,
    onFormStateChange,
    tableSelections,
    setTableSelections,
    columnConfig,
    showSamplingConfigDialog,
    setShowSamplingConfigDialog,
    pdfData,
    onValidationError,
    validationErrors,
    templateDataLoading,
    templateData,
    currentStep,
    setCurrentStep,
    onNextStep,
    fetchAndSetReportData,
    renderExtraButtons,
    deleteButtonStep,
    onDelete,
    showDelete,
    loading,
}) => {
    const [formState, setFormState] = useState({});
    const [genericError, setGenericError] = useState(null);

    useEffect(() => {
        setFormState(initialState || {});
    }, [initialState]);

    const handleNext = () => {
        const currentStepData = steps[currentStep];

        if (currentStepData.fields) {
            const validationErrors = currentStepData.fields.reduce((errors, field) => {
                if (field.required && (typeof formState[field.id] === "undefined" || formState[field.id] === "")) {
                    errors.push({ id: field.id, error: "This field is required" });
                }
                return errors;
            }, []);

            if (validationErrors.length > 0) {
                onValidationError(validationErrors);
                return;
            }
        }

        // Clear the validation errors when moving to the next step
        onValidationError([]);

        if (currentStep === 0) {
            onNextStep(formState);
        } else if (currentStep < steps.length - 1) {
            setCurrentStep((prevStep) => prevStep + 1);
            onStepChange(currentStep + 1);
        } else if (currentStep === steps.length - 1) {
            onSubmit(formState);
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            // Clear the validation errors for the current step
            onValidationError([]);

            setCurrentStep((prevStep) => prevStep - 1);
            onStepChange(currentStep - 1, true);
        }
    };

    const handleFormStateChange = (newState) => {
        const updatedState = { ...formState, ...newState };
        setFormState(updatedState);
        onFormStateChange(updatedState);
    };

    const renderStepContent = () => {
        const { fields, tables, customStep } = steps[currentStep];

        if (customStep) {
            return customStep.render(formState, pdfData);
        }

        if (currentStep === deleteButtonStep && templateDataLoading) {
            return <LoadingSpinner />;
        }

        return (
            <>
                {fields.length > 0 && (
                    <Form
                        fields={fields}
                        formState={formState}
                        onFormStateChange={handleFormStateChange}
                        validationErrors={validationErrors}
                    />
                )}
                {tables &&
                    tables.map((table) => {
                        const reportObject = templateData?.reports?.find(
                            (report) => report.report_uuid === table.reportUUID && report.key === table.key,
                        );

                        return reportObject ? (
                            <MultiStepFormReportTable
                                key={table.key}
                                reportUUID={table.reportUUID}
                                visibleColumns={table.visibleColumns}
                                formState={formState}
                                tableKey={table.key}
                                onFormStateChange={handleFormStateChange}
                                tableSelections={tableSelections}
                                setTableSelections={setTableSelections}
                                columnConfig={columnConfig}
                                showSamplingConfigDialog={showSamplingConfigDialog}
                                setShowSamplingConfigDialog={setShowSamplingConfigDialog}
                                templateData={templateData}
                                fetchAndSetReportData={fetchAndSetReportData}
                            />
                        ) : null;
                    })}
            </>
        );
    };

    const renderButtons = () => {
        if (currentStep === 0) {
            return (
                <>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={handleNext} disabled={validationErrors.length > 0}>
                        Next
                    </Button>
                </>
            );
        } else if (currentStep === steps.length - 1) {
            return (
                <>
                    <Button onClick={handlePrev}>Previous</Button>
                    <Button onClick={handleNext}>Submit</Button>
                </>
            );
        } else {
            return (
                <>
                    <Button onClick={handlePrev}>Previous</Button>
                    {renderExtraButtons && renderExtraButtons()}
                    <Button onClick={handleNext}>Next</Button>
                </>
            );
        }
    };

    return (
        <Page loading={loading}>
            <Card title={title} onDelete={onDelete} showDelete={showDelete}>
                <ProgressBar
                    steps={steps.length}
                    currentStep={currentStep}
                    stepTitles={steps.map((step) => step.title)}
                />
                {renderStepContent()}
                <div className={styles.buttonArray}>{renderButtons()}</div>
                <Snackbar
                    message={genericError?.message}
                    onClose={() => setGenericError(null)}
                    severity={genericError?.severity}
                    duration={genericError?.duration}
                />
            </Card>
        </Page>
    );
};

export default MultiStepForm;
