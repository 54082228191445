import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getReport } from "../../../api/reportingAPI";
import { getErrorText } from "../../../utils/errors";
import { ArrowUpwardRounded, ArrowDownwardRounded, ChevronRightRounded, ChevronLeftRounded } from "@material-ui/icons";
import { defaultColumnFormats } from "../../../utils/columnFormats";
import styles from "../../../styles/common/reportTable.module.css";
import LoadingSpinner from "../LoadingSpinner";
import Button from "../Button";

/**
 * Renders a simple report table (no search, filters, or column selection)
 *
 * @param {string} reportUUID - The report to be fetched
 * @param {(columns: string[]) => void} onColumnsChanged - Called to tell the parent what columns are present
 * @param {string[]} [visibleColumns] - Array of columns that are shown in the table
 * @param {(Object) => string} [rowLinks] - Called when rendering each row, can return a link for when the row is clicked
 * @param {Object} [columnFormats] - Object containing functions called when rendering each cell, can be used to alter contents
 * @param {Object} [columnAlignments] - Object containing an optional CSS "text-align" value for each column
 * @param {Object} reportingParams - Object containing report parameters like sorting, pagination, and direction
 * @param {(Object) => void} setReportingParams - Function to set reporting parameters, which will re-trigger data fetching
 */
const BasicReportTable = ({
    reportUUID,
    onColumnsChanged,
    visibleColumns,
    rowLinks,
    columnFormats,
    columnAlignments,
    reportingParams = {},
    setReportingParams,
}) => {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showNextPage, setShowNextPage] = useState(false);

    useEffect(() => {
        setError(false);
        setLoading(true);
        setShowNextPage(false);

        getReport(reportUUID, reportingParams.count ? reportingParams : { ...reportingParams, count: 50 })
            .then((data) => {
                setData(data.data);
                onColumnsChanged?.(data.data?.outputs?.map((output) => output.name));
                setShowNextPage(data.nextPage > (reportingParams.page ?? 1));
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, [reportUUID, JSON.stringify(reportingParams)]);

    const onHeaderClick = (header) => {
        setReportingParams((prevParams) => {
            const newOrder = prevParams.order === header ? prevParams.order : header;
            const newDirection =
                prevParams.order === header ? (prevParams.direction === "asc" ? "desc" : "asc") : "asc";
            return { ...prevParams, order: newOrder, direction: newDirection };
        });
    };

    const changePage = (increment) => {
        setReportingParams((prevParams) => {
            return { ...prevParams, page: prevParams.page + increment };
        });
    };

    const isColumnVisible = (col) =>
        !col.match(/uuid|id/i) && (!visibleColumns?.length || visibleColumns.includes(col));
    const columnAlignment = (col, type) => columnAlignments?.[col] ?? defaultColumnFormats[type]?.alignment ?? "left";

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return getErrorText(error);
    }

    if (!data.rows?.length) {
        return "No Rows Found";
    }

    return (
        <div className={styles.tableWrapper}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {data.outputs
                            .filter((output) => isColumnVisible(output.name))
                            .map((output) => (
                                <th
                                    key={output.name}
                                    onClick={data.rows.length > 1 ? () => onHeaderClick(output.name) : null}
                                    style={{ textAlign: columnAlignment(output.name, output.type) }}
                                >
                                    {output.name}
                                    &nbsp;
                                    {output.name === reportingParams.order &&
                                        (reportingParams.direction === "asc" ? (
                                            <ArrowUpwardRounded />
                                        ) : (
                                            <ArrowDownwardRounded />
                                        ))}
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {data.rows.map((row, i) => {
                        const rowAsObject = data.outputs.reduce(
                            (prev, curr, j) => ({ ...prev, [curr.name]: row.values[j] }),
                            {}
                        );
                        const rowlink = rowLinks?.(rowAsObject);
                        const linkTag = (content) => {
                            const cellContent = <div>{content}</div>;
                            if (!rowlink) {
                                return cellContent;
                            }

                            if (typeof rowlink === "string") {
                                return (
                                    <Link to={{ pathname: rowlink, state: { rowData: rowAsObject } }}>
                                        {cellContent}
                                    </Link>
                                );
                            } else if (typeof rowlink === "object" && rowlink.pathname) {
                                return (
                                    <Link
                                        to={{
                                            pathname: rowlink.pathname,
                                            state: rowlink.state,
                                        }}
                                        className={styles.clickableRow}
                                    >
                                        {cellContent}
                                    </Link>
                                );
                            }

                            return cellContent; // fallback for unexpected rowlink types
                        };

                        return (
                            <tr key={`row-${i}`}>
                                {row.values.map((value, j) => {
                                    const { name, type } = data.outputs[j];
                                    const formatFunction = columnFormats?.[name] ?? defaultColumnFormats[type]?.format;
                                    return isColumnVisible(name) ? (
                                        <td key={`row-${i}-${name}`} style={{ textAlign: columnAlignment(name, type) }}>
                                            {linkTag(formatFunction ? formatFunction(value) : value)}
                                        </td>
                                    ) : null;
                                })}
                            </tr>
                        );
                    })}
                    <tr className={styles.navigationRow}>
                        <td colSpan={999}>
                            <div className={styles.navigation}>
                                {reportingParams.page > 1 && (
                                    <Button size="small" onClick={() => changePage(-1)}>
                                        <ChevronLeftRounded /> Previous Page
                                    </Button>
                                )}
                                {showNextPage && (
                                    <Button size="small" onClick={() => changePage(1)}>
                                        Next Page <ChevronRightRounded />
                                    </Button>
                                )}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default BasicReportTable;
